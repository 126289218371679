<template>
    <el-button type="warning" size="mini" icon="el-icon-delete" :disabled="disabled">删除</el-button>
</template>

<script>
export default {
    name: "DelButton",
    props: {
        rule: '',
        disabled: {
            type: Boolean,
            default: false
        }
    },
}
</script>
