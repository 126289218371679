<template>
<!--  <vue-neditor-wrap :key="skey" v-model="values" :config="config" @input="input"></vue-neditor-wrap>-->
    <vue-ueditor-wrap :key="skey" v-model="values" :config="config" @input="input"></vue-ueditor-wrap>
</template>

<script>
export default {
    name: "UEditor",
    data(){
        return{
            values:'',
            isInit:false,
            config: {
              zIndex:3000,
              // theme:'notadd',
                // 编辑器不自动被内容撑高
                autoHeightEnabled: false,
                autoFloatEnabled:false,
                // 初始容器高度
                initialFrameHeight: 240,
                // 初始容器宽度
                initialFrameWidth: '100%',
                // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
                serverUrl: this.$config.editorUrl+'/UEditor',
                // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
                UEDITOR_HOME_URL: '/ueditor/',
              toolbars: [[
                '|','insertimage',
              ]],
            }
        }
    },
    model:{
        prop:'value',
        event:'input'
    },
    props:{
        skey:String,
        value:String
    },
    methods:{
        input(e){
            this.$emit('input',e);
        }
    },
    watch:{
        value(n,o){
          console.log(n);
          console.log(this.isInit);
            let _this = this;
            // if(!this.isInit) {
                this.values = n;
                // this.isInit = true;
            // }
        }
    },
}
</script>
<style>
.no-line-height .el-form-item__content{line-height: unset !important;}
</style>
