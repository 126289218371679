<template>
    <div class="bg-light padding-20">
        <div class="margin-top">
<!--          {{formData}}-->
            <el-form :model="formData" :rules="rules" ref="form" :label-width="params.edit.labelWidth ? params.edit.labelWidth : '150px'">
                <el-form-item label="ID" v-if="formData && formData.id">
                    {{formData.id}}
                </el-form-item>
                <el-form-item label="年份" prop="year" v-if="params.edit.chooseYear" :rules="[{required: true,message:'年份必填'}]">
                    <year-select v-model="year" @change="changeYear" style="width: 500px;"></year-select>
                </el-form-item>
                <el-row :gutter="10">
                    <el-col :span="24 / (params.edit.cols ? params.edit.cols : 1)" v-for="(item,index) in editParams">
                        <el-form-item :label="item.name" :prop="item.value" :class="item.type ==='editor' ? 'no-line-height' : ''" v-if="item.check ? formData[item.check.key] === item.check.val : true">
                            <template v-if="item.edit">
                                <type-select v-if="item.type === 'select'" v-model="formData[item.value]" @change="(e)=>$set(formData,item.value,e)" :options="item.options ? item.options : null" :data="item.data ? item.data : null" style="width: 500px;" :can-add="item.canAdd ? item.canAdd : false" :pid="item.pid ? formData[item.pid] : ''" :pidKey="item.pid ? item.pid : ''" :pid-choose="item.pidChoose ? item.pidChoose : false" :filterable="item.filterable ? item.filterable : false"></type-select>
                                <company-select v-else-if="item.type === 'company'" v-model="formData[item.value]" @change="(e)=>$set(formData,item.value,e)" style="width: 500px;"></company-select>
                                <editor v-model="formData[item.value]" ref="editor" v-else-if="item.type === 'editor'" style="width:100%;"/>
                                <clean-editor v-model="formData[item.value]" ref="cleanEditor" v-else-if="item.type === 'cleanEditor'" style="width:100%;"/>
                                <tags v-model="formData[item.value]" v-else-if="item.type === 'tags'" style="width:100%;"/>
                                <el-input type="textarea" :rows="8" :placeholder="'请输入'+item.name" v-model="formData[item.value]" v-else-if="item.type === 'textarea'" style="width: 500px;" :maxlength="item.max ? item.max : ''" show-word-limit>
                                </el-input>
                                <head-img-upload v-model="formData.head_img" :img="formData.head_img ? $config.assetsUrl + formData.head_img : ''" v-else-if="item.value === 'head_img'" @change="(e)=>$set(formData,'head_img',e ? e : '')"></head-img-upload>
	                            <image-upload @change="(e)=>{formData[item.value] = e;}" :file="formData.files" v-else-if="item.type === 'image'"></image-upload>
                                <push-time v-model="formData.todo_time" style="width: 500px;" v-else-if="item.value === 'todo_time'"></push-time>
                                <push-time v-model="formData.push_time" style="width: 500px;" v-else-if="item.value === 'push_time'"></push-time>
	                            <push-time v-model="formData[item.value]" style="width: 500px;" v-else-if="item.type === 'datepicker'"></push-time>
                                <year-select v-model="formData.year" style="width: 500px;" v-else-if="item.type === 'year'"></year-select>
                                <file-upload v-model="formData[item.value]" v-else-if="item.type === 'file'" :files="formData['files']" v-bind="item.comProp ? item.comProp : null"></file-upload>
	                            <template v-else-if="item.type === 'oneFile'">
	                              <file-upload v-model="formData[item.value]" :files="formData[item.value]" v-bind="item.comProp ? item.comProp : null" @onName="(e)=>onFileName(e,item)"></file-upload>
	                            </template>
                                <template v-else-if="item.type === 'totime'">
                                    <el-input v-model="formData[item.value]" style="width: 400px;"></el-input>
                                    <price-time-select class="margin-left" v-model="formData.time_type" style="width: 90px;"></price-time-select>
                                </template>
                                <el-switch
                                    v-else-if="item.type === 'switch'"
                                    v-model="formData[item.value]"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#13ce66">
                                </el-switch>
                                <el-switch
                                    v-model="formData[item.value]"
                                    active-color="#13ce66"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-text="启用"
                                    inactive-text="禁用" v-else-if="item.type === 'state'">
                                </el-switch>
                              <el-switch
                                v-model="formData[item.value]"
                                active-color="#13ce66"
                                :active-value="1"
                                :inactive-value="0"
                                active-text="是"
                                inactive-text="否" v-else-if="item.type === 'bool'">
                              </el-switch>
                                <province-select v-model="province" v-else-if="item.type === 'province'" @change="changeProvince"></province-select>
                                <el-form-item v-else-if="item.type === 'vipPrice'">
                                    <price-vip @change="changeVipPrice" :value="{price:formData.price,user_vip_type:formData.user_vip_type}"></price-vip>
                                    <!--                                    <el-radio-group v-model="formData.user_vip_type" @change="changeVip">-->
                                    <!--                                        <el-radio label="free">完全公开</el-radio>-->
                                    <!--                                        <el-radio label="user">注册会员</el-radio>-->
                                    <!--                                        <el-radio label="vip">VIP</el-radio>-->
                                    <!--                                    </el-radio-group>-->
                                    <!--                                    <el-form-item v-if="formData.user_vip_type === 'vip'" prop="price" :rules="{validator:$utils.rulePriceNotZero}">-->
                                    <!--                                        <el-radio-group v-model="priceType" @change="changePriceType">-->
                                    <!--                                            <el-radio :label="1">￥9.9</el-radio>-->
                                    <!--                                            <el-radio :label="2">￥19.9</el-radio>-->
                                    <!--                                            <el-radio :label="3">￥3880</el-radio>-->
                                    <!--                                            <el-radio :label="4">其它</el-radio>-->
                                    <!--                                        </el-radio-group>-->
                                    <!--                                        <el-input v-model="formData.price" maxlength="10" style="width: 300px;" class="margin-left" v-if="formData.user_vip_type === 'vip'" @input="changePrice"></el-input>设置单条付费价格-->
                                    <!--                                    </el-form-item>-->
                                </el-form-item>
                                <el-form-item v-else-if="item.type === 'vip'">
                                    <block-vip v-model="formData.user_vip_type"></block-vip>
                                </el-form-item>
                              <el-form-item v-else-if="item.type === 'year-month-select'">
                                <year-month-select v-model="formData[item.value]"></year-month-select>
                              </el-form-item>
	                            <template v-else-if="item.type === 'radioGroup'">
		                            <el-radio-group v-model="formData[item.value]" @change="(e) => onRadioGroupChange(e,item)">
			                            <el-radio :label="st.id" v-for="(st,si) in item.options">{{ st.name }}</el-radio>
		                            </el-radio-group>
	                            </template>
	                            <speaker-select v-else-if="item.type === 'speakerSelect'" v-model="formData[item.value]"></speaker-select>
                                <el-input v-model.trim="formData[item.value]" :style="{width:params.edit.inputWidth ? params.edit.inputWidth : '500px'}" :maxlength="item.max ? item.max : ''" show-word-limit v-else>
                                    <template slot="prepend" v-if="item.prepend">{{item.prepend}}</template>
                                    <template slot="append" v-if="item.append">{{item.append}}</template>
                                </el-input>
                              <span class="margin-left" v-if="item.tips">{{item.tips ? item.tips : ''}}</span>
                            </template>
                            <template v-else>
                                {{formData[item.value]}}
                            </template>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('form')">保存</el-button>
                    <el-button @click="$router.back()">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: "PolicyEdit",
    data() {
        return {
            url:'',
            params:null,
            editParams:null,
            fileList: [],
            formData: {
                id: '',
            },
            editData:null,
            rules: {},
            province:'',
            year:'',
            priceType:1,
        };
    },
    created() {
        let _config = window.config.paths();
        let _path = this.$route.path.replace('/edit','');

        if(this.$route.params.type){
            this.$set(this.formData,'type',this.$route.params.type);
        }

        this.params = _config[_path];


				// if(this.params.copyIdCol){
				// 	this.formData[this.params.copyIdCol] = this.$route.query.id ? this.$route.query.id : '';
				// }else {
				// 	this.formData.id = this.$route.query.id ? this.$route.query.id : '';
				// }
				
				
	
	    this.formData.id = this.$route.query.id ? this.$route.query.id : '';
	    this.formData.zhuanti_id = this.$route.query.zhuanti_id ? this.$route.query.zhuanti_id : '';

        if(this.$route.query.pid){
            this.formData.pid = this.$route.query.pid;
        }
	
	    // this.editParams = this.params['edit']['params'];
	
	    this.editParams = this.formData.id ? this.params['edit']['params'] : (this.params['add'] && this.params['add']['params'] ? this.params['add']['params'] : this.params['edit']['params']);
	
	    this.editData = this.params['edit']['editData'] ? this.params['edit']['editData'] : {};
				
				// if(this.params.copyId && this.params.copyIdCol){
				// 	this.formData[this.params.copyIdCol] = this.formData.id;
				// }
				
        for(let i = 0;i<this.editParams.length;i++){
            // if(this.params[i].value === 'permission' || this.params[i].value === 'attachment') {
            //     this.$set(this.formData,this.params[i].value,[]);
            // }else{
            //     this.$set(this.formData,this.params[i].value,'');
            // }

            if(this.editParams[i].edit) {
                this.$set(this.formData, this.editParams[i].value, this.editParams[i].def ? this.editParams[i].def : '');

                if (this.editParams[i].type === 'vipPrice') {
                    this.$set(this.formData, 'price', '');
                }
								
								console.log(this.formData);

                this.rules[this.editParams[i].value] = [];

                if (this.editParams[i].rq) {
                    this.rules[this.editParams[i].value].push({
                        required: true,
                        message: this.editParams[i].name + '必填'
                    });

                    // if(this.editParams[i].type === 'price'){
                    //     this.rules[this.editParams[i].value] = [{required: true, message: this.editParams[i].name + '必填',validator:this.$utils.rulePriceCanZero}];
                    // }else if(this.editParams[i].type === 'number'){
                    //     this.rules[this.editParams[i].value] = [{required: true, message: this.editParams[i].name + '必填',validator:this.$utils.must09}];
                    // }else {
                    //     this.rules[this.editParams[i].value] = [{required: true, message: this.editParams[i].name + '必填'}];
                    // }
                }

                if (this.editParams[i].type === 'price') {
                    this.rules[this.editParams[i].value].push({validator: this.$utils.rulePriceCanZero});
                } else if (this.editParams[i].type === 'number') {
                    this.rules[this.editParams[i].value].push({validator: this.$utils.must09});
                } else if (this.editParams[i].type === 'float') {
                  this.rules[this.editParams[i].value].push({pattern: this.$regExp.float,message: '请输入正确的数字格式'});
                } else if (this.editParams[i].type === 'percent') {
                  this.rules[this.editParams[i].value].push({pattern: this.$regExp.percent,message: '请输入正确的百分比'});
                }else if (this.editParams[i].type === 'textarea') {
                    // this.rules[this.editParams[i].value].push({validator:this.$utils.mustTrim});
                    // this.rules[this.editParams[i].value].push({validator: this.$utils.mustNormalWord});
                } else {
                    // this.rules[this.editParams[i].value].push({validator: this.$utils.mustNormalWord});
                  if(this.editParams[i].regexp) {
                    this.rules[this.editParams[i].value].push({pattern: this.$regExp[this.editParams[i].regexp], message: '请填写正确数值'});
                  }
                }
            }

            // if(this.editParams[i].type === 'province'){
            //     this.province = {code:}
            // }
        }
    },
    mounted() {
        // this.getTypeList();
        if(this.formData.id) {
            this.getDetail();
            this.url = this.params.apiUrl+'/edit';
        }else{
            this.url = this.params.apiUrl+'/add';
        }
        // this.getPermission();
    },
    methods: {
	    onRadioGroupChange(e,param){
				console.log(e,param);
				if(param.onChange){
					param.onChange(e,this.formData)
				}
	    },
	    onFileName(e,prop){
				if(prop.comProp && prop.comProp.fileName){
					this.formData[prop.comProp.fileName] = e;
				}
	    },
        changeProvince(e){
            this.province = e;
            this.formData.province_code = e.code;
            this.formData.province_name = e.name;
        },
        changeYear(e){
            this.year = e;
            this.formData.year = e;
            this.formData.id = '';

            this.getDetail();
        },
        getDetail() {
            let _this = this;
            this.$http.httpPost({
                url: this.params.apiUrl+'/detail',
                datas: { id: this.formData.id ,year:this.params.edit.chooseYear ? this.year : '',...this.editData},
                success(res) {
                    _this.formData = res ? JSON.parse(JSON.stringify(res)) : null;

                    if(res){
                        if(_this.params.edit.chooseYear && res.year){
                            _this.year = res.year;
                        }

                        for(let i = 0;i<_this.editParams.length;i++){
                            if(_this.editParams[i].type === 'vip'){
                                if(parseFloat(res.price) === 9.9){
                                    _this.priceType = 1;
                                }else if(parseFloat(res.price) === 19.9){
                                    _this.priceType = 2;
                                }else if(parseFloat(res.price) === 3880){
                                    _this.priceType = 3;
                                }else{
                                    _this.priceType = 4;
                                }
                            }
                        }
                    }else{
                        _this.formData = {};

                        if(_this.params.edit.chooseYear){
                            _this.formData.year = _this.year;
                        }

                        for(let i = 0;i<_this.editParams.length;i++){
                            if(_this.editParams[i].type === 'price'){
                                _this.$set(_this.formData,_this.editParams[i].value,0);
                            }else if(_this.editParams[i].type === 'vip'){

                                console.log(parseFloat(res.price));
                                if(parseFloat(res.price) === 9.9){
                                    _this.priceType = 1;
                                }else if(parseFloat(res.price) === 19.9){
                                    _this.priceType = 2;
                                }else if(parseFloat(res.price) === 3880){
                                    _this.priceType = 3;
                                }else{
                                    _this.priceType = 4;
                                }
                            }else{
                                _this.$set(_this.formData,_this.editParams[i].value,'');
                            }
                        }
                    }

                    if(_this.formData.province_code && _this.formData.province_name){
                        _this.province = {code:_this.formData.province_code,name:_this.formData.province_name};
                    }
                }
            });
        },
        create() {
            let _this = this;

            if(this.formData.id) {
                this.url = this.params.apiUrl+'/edit';
            }else{
                this.url = this.params.apiUrl+'/add';
            }

            this.$http.httpPost({
                url: this.url,
                datas: {...this.formData,...this.editData},
                success(res) {
                    _this.$utils.success('保存成功！');
                    _this.$router.back();
                }
            });
        },
        submitForm(formName) {
            //存在回调的方法，因为回调外和回调内的作用域不一致，所以务必为 _this 复制 this，否则在回调中无法访问this对象
            let _this = this;
            //通过传递form表单ref名称，来调用表单的validate方法
            this.$refs[formName].validate(valid => {
                //为true时验证通过
                if (valid) {
                    _this.create();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        changeVipPrice(e){
            this.formData.price = e.price;
            this.formData.user_vip_type = e.user_vip_type;
        },
    }
};
</script>
