<template>
  <div>
    <div class="margin-top">
<!--      {{formData}}-->
      <el-form :model="formData" ref="form" label-width="170px">
        <el-form-item label="ID" v-if="formData && formData.id">
          {{formData.id}}
        </el-form-item>
        <el-form-item label="企业名称" v-if="companyInfo">
          {{companyInfo.title}}--医药数据
        </el-form-item>
        <el-form-item label="年份" prop="year" :rules="[{required: true,message:'年份必填',trigger:'blur'}]">
          <year-select placeholder="-请先选择年份-" v-model="year" @change="changeYear" style="width: 500px;"></year-select>
          <el-button type="danger" class="margin-left" v-if="formData && formData.id" @click="toDel">删除{{year}}年数据</el-button>
        </el-form-item>
        <template v-if="formData.year">
          <template v-for="(t,i) in formData.data_col">
            <template v-if="t">
              <div class="bg-gray-3 padding margin-bottom text-center f20 danger" style="position: sticky;top:0;z-index: 3;">{{t.title}}</div>
              <template v-for="(t2,i2) in t.datas">
                <div class="border-bottom padding-bottom margin-bottom f16">
                  {{t2.label}}
                  <div class="margin-top f14 dark-3" v-if="t2.des">{{t2.des}}</div>
                </div>
                <el-row :gutter="10">
                  <el-col :span="item.col ? parseInt(item.col) : 6" v-for="(item,index) in t2.values">
                    <el-form-item :label="item.label" :prop="'data_col.'+i+'.datas.'+i2+'.values.'+index+'.val'" :rules="item.rule ? [{required: item.rq ? (typeof item.rq === 'string' ? item.rq.bool() : item.rq) : false,message:'不能为空'},{pattern:item.rule ? $regExp[item.rule] : null,message:'格式不正确'}] : [{required: item.rq ? (typeof item.rq === 'string' ? item.rq.bool() : item.rq) : false,message:'不能为空'}]">
                      <el-switch
                          v-model="item.val"
                          active-color="#13ce66"
                          active-value="1"
                          inactive-value="0"
                          active-text="是"
                          inactive-text="否" v-if="item.type === 'state'">
                      </el-switch>
                      <el-select v-model="item.val" v-else-if="item.type === 'peiyang'" clearable>
                        <el-option label="暂无" value=""></el-option>
                        <el-option label="内部培训" value="内部培训"></el-option>
                        <el-option label="外聘讲师培训" value="外聘讲师培训"></el-option>
                        <el-option label="外部培训" value="外部培训"></el-option>
                        <el-option label="管培生培训" value="管培生培训"></el-option>
                      </el-select>
                      <el-select v-model="item.val" v-else-if="item.type === 'peixun'" clearable>
                        <el-option label="暂无" value=""></el-option>
                        <el-option label="至少每月一次" value="至少每月一次"></el-option>
                        <el-option label="至少每季度一次" value="至少每季度一次"></el-option>
                        <el-option label="至少每半年一次" value="至少每半年一次"></el-option>
                        <el-option label="至少每年一次" value="至少每年一次"></el-option>
                      </el-select>
                      <push-time v-model="item.val" style="width: 100%;" v-else-if="item.type === 'date'"></push-time>
                      <el-input v-model="item.val" rows="3" type="textarea" :maxlength="item.max ? item.max : null" v-else-if="item.type === 'textarea'"></el-input>
                      <el-input v-model="item.val" style="max-width: 310px;" :maxlength="item.max ? item.max : null" v-else>
                        <template slot="append" v-if="item.dw">{{item.dw}}</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </template>
          </template>
        </template>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">保存</el-button>
          <el-button @click="$router.back()">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<!--`data_yysr` longtext COMMENT '营业收入',-->
<!--`data_wlfy` longtext COMMENT '物流费用',-->
<!--`data_bwx` longtext COMMENT '保温箱',-->
<!--`data_car` longtext COMMENT '车辆信息',-->
<!--`data_cangchu` longtext COMMENT '仓储信息',-->
<!--`data_yunshu` longtext COMMENT '运输组织',-->
<!--`data_rencai` longtext COMMENT '人才情况',-->
<!--`data_kehu` longtext COMMENT '主要客户',-->
<!--`data_xinxixitong` longtext COMMENT '信息系统',-->
<!--`data_zidonghua` longtext COMMENT '自动化设备',-->
<script>
export default {
  name: "yiyao",
  data(){
    return{
      formData:{
        id:'',
        tid:'',
        year:'',
        data_col:null
      },
      year:'',
      rules:{

      },
      url:'',

      defData:{
        data_yysr: {
          title:'主营营业收入',
          datas:[
            {
              id:'data1',
              label:'主营营业收入',
              values:[
                {label: '主营业务营业收入',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
                {label: '主营业务利润',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
                {label: '医药冷链业务营业收入',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
                {label: '医药冷链业务利润',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
              ]
            },
            {
              id:'data2',
              label:'各品类冷链药品营收占比',
              values:[
                {label: '疫苗类',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: '血液制品类',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: '其他生物制品',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: 'IVD',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: '其他医疗器械',val:'0',dw:'%',rule:'money',max:10,rq:true},
              ]
            },
          ]
        },
        data_wlfy:{
          title:'物流费用',
          datas:[
            {
              id:'data1',
              label:'医药物流费用',
              values:[
                {label: '总费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
                {label: '医药物流仓储费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '医药物流运输费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '医药物流管理费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '其他费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
              ]
            },
            {
              id:'data2',
              label:'医药冷链物流费用',
              values:[
                {label: '总费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
                {label: '冷链物流仓储费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '冷链物流运输费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '冷链物流管理费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '其他费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
              ]
            },
            {
              id:'data3',
              label:'各品类冷链药品物流费用占比',
              values:[
                {label: '疫苗类',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
                {label: '血液制品类',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
                {label: '其他生物制品',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
                {label: 'IVD',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
                {label: '其他医疗器械',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
              ]
            }
          ]
        },
        data_bwx:{
          title:'保温箱&冷藏箱信息',
          datas:[
            {
              id:'data1',
              label:'保温箱&冷藏箱总量',
              values:[
                {label: '总数量',val:'0',dw:'个',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '自有',val:'0',dw:'个',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '租赁',val:'0',dw:'个',rule:'positiveInteger',max:10,col:8,rq:true},
                {label: '年度使用费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,rq:true},
                {label: '验证费用',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '品牌',val:'',dw:'',max:30},
                {label: '是否经过GSP验证',val:'0',type:'state',rq:true},
                {label: '温湿度记录仪品牌',val:'',max:30},
                {label: '产能',val:'0',dw:'个/年',rule:'positiveInteger',max:10,rq:true},
              ]
            },
          ]
        },
        data_car:{
          title:'车辆信息',
          datas:[
            {
              id:'data1',
              label:'医药专用运输车',
              values:[
                {label: '总数量',val:'0',dw:'辆',rule:'positiveInteger',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data2',
              label:'医药专用运输车辆自有',
              values:[
                {label: '合计',val:'0',dw:'辆',rule:'positiveInteger',max:10,col:24,rq:true},
                {label: '4.2m以下',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '4.2m~7.2m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '7.6m~9.6m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '12.5m~17m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
              ]
            },
            {
              id:'data3',
              label:'医药专用运输车辆外协',
              values:[
                {label: '合计',val:'0',dw:'辆',rule:'positiveInteger',max:10,col:24,rq:true},
                {label: '4.2m以下',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '4.2m~7.2m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '7.6m~9.6m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '12.5m~17m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
              ]
            },
            {
              id:'data4',
              label:'冷藏车总数',
              values:[
                {label: '总数量',val:'0',dw:'辆',rule:'positiveInteger',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data5',
              label:'冷藏车自有',
              values:[
                {label: '合计',val:'0',dw:'辆',rule:'positiveInteger',max:10,col:24,rq:true},
                {label: '4.2m以下',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '4.2m~7.2m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '7.6m~9.6m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '12.5m~17m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
              ]
            },
            {
              id:'data6',
              label:'冷藏车外协',
              values:[
                {label: '合计',val:'0',dw:'辆',rule:'positiveInteger',max:10,col:24,rq:true},
                {label: '4.2m以下',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '4.2m~7.2m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '7.6m~9.6m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
                {label: '12.5m~17m',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
              ]
            },
            {
              id:'data7',
              label:'其他车型',
              values:[
                {label: '车型',val:''},
                {label: '数量',val:'0',dw:'辆',rule:'positiveInteger',max:10,rq:true},
              ]
            },
            {
              id:'data8',
              label:'车辆品牌信息',
              values:[
                {label: '普通专用车辆品牌',val:'',col:12,max:30},
                {label: '冷藏车辆品牌',val:'',col:12,max:30},
                {label: '改装车辆生产厂家',val:'',col:12,max:30},
                {label: '制冷机组厂家',val:'',col:12,max:30},
                {label: '温湿度记录仪品牌',val:'',col:12,max:30},
                {label: '运输管理信息系统品牌',val:'',col:12,max:30},
                {label: '验证费用',val:'0',dw:'万元',rule:'money',max:10,col:12,rq:true},
                {label: '产能',val:'0',dw:'辆/年',col:12,rule:'positiveInteger',max:10,rq:true},
              ]
            },
          ]
        },
        data_cangchu:{
          title:'仓储信息',
          datas:[
            {
              id:'data1',
              label:'仓储基本信息',
              values:[
                {label: '仓库总个数',dw:'个',val:'0',rule:'positiveInteger',max:10,col:8,rq:true},
                {label: '仓库总面积',dw:'㎡',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '仓库总容积',dw:'㎥',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data2',
              label:'冷藏库（2℃~8℃）',
              values:[
                {label: '总面积',dw:'㎡',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '总容积',dw:'㎥',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data3',
              label:'冷冻库（-15℃～-25℃以下）',
              values:[
                {label: '总面积',dw:'㎡',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '总容积',dw:'㎥',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data4',
              label:'阴凉库（0℃～20℃）',
              values:[
                {label: '总面积',dw:'㎡',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '总容积',dw:'㎥',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data5',
              label:'常温库（10℃～30℃）',
              values:[
                {label: '总面积',dw:'㎡',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '总容积',dw:'㎥',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data6',
              label:'其他温区库',
              values:[
                {label: '总面积',dw:'㎡',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '总容积',dw:'㎥',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data7',
              label:'自动化立体库',
              values:[
                {label: '个数',dw:'个',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '总面积',dw:'㎡',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '总容积',dw:'㎥',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '集成商',dw:'',val:'',max:30,col:6},
                {label: '出入库能力',dw:'箱/时',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '分拣能力',dw:'件/时',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '平均在库天数',dw:'天',val:'0',rule:'money',max:10,col:6,rq:true},
              ]
            },
            {
              id:'data8',
              label:'普通库',
              values:[
                {label: '个数',dw:'个',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '总面积',dw:'㎡',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '总容积',dw:'㎥',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '出入库能力',dw:'箱/时',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '分拣能力',dw:'件/时',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '平均在库天数',dw:'天',val:'0',rule:'money',max:10,col:6,rq:true},
              ]
            },
            {
              id:'data9',
              label:'',
              values:[
                {label: '仓库制冷机组品牌',dw:'',val:'',rule:'',max:50,col:12},
                {label: '仓库温湿度记录仪品牌',dw:'',val:'',rule:'',max:50,col:12},
                {label: '仓库信息管理系统品牌',dw:'',val:'',rule:'',max:50,col:12},
                {label: '仓库所在省份地址',dw:'',val:'',rule:'',max:50,col:12},
              ]
            },
          ]
        },
        data_yunshu:{
          title:'运输组织',
          datas:[
            {
              id:'data1',
              label:'运输方式',
              values:[
                {label: '自有运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '外协运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data2',
              label:'运输模式',
              values:[
                {label: '干线运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '支线运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '同城配送',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data3',
              label:'货源结构',
              values:[
                {label: '整车运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '零担运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data4',
              label:'车辆平均周转天数',
              des:'车辆往返任务完结时间',
              values:[
                {label: '干线',dw:'天',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '支线',dw:'天',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '城配',dw:'天',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data5',
              label:'车辆满载率',
              values:[
                {label: '干线',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '支线',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '城配',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data6',
              label:'车辆平均月度行驶里程（KM）',
              values:[
                {label: '月平均行驶里程',dw:'KM',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data7',
              label:'核心运输线路',
              values:[
                {label: '干线',dw:'',val:'',col:8},
                {label: '支线',dw:'',val:'',col:8},
                {label: '城配',dw:'',val:'',col:8},
              ]
            },
          ]
        },
        data_rencai:{
          title:'人才情况',
          datas:[
            {
              id:'data1',
              label:'总人数',
              values:[
                {label: '公司总人数（人）',dw:'人',val:'0',rule:'positiveInteger',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data2',
              label:'性别方面',
              values:[
                {label: '男性',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '女性',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data3',
              label:'学历情况',
              values:[
                {label: '初中以下',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '初中/中职',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '高中/高职',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '本科',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '硕士及以上',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
              ]
            },
            {
              id:'data4',
              label:'工资水平',
              values:[
                {label: '3000元/月以下',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '3000-5000元/月',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '5000-10000元/月',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '10000元/月以上',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
              ]
            },
            {
              id:'data5',
              label:'年龄结构人数',
              values:[
                {label: '20-30岁占比',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '30-40岁占比',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '40-50岁占比',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '50岁以上',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
              ]
            },
            {
              id:'data6',
              label:'人员稳定性方面',
              values:[
                {label: '一线从业人员流失率',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '需求人数',dw:'人',tip:'一线从业人员需求人数',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '助理级从业人员流失率',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '需求人数',dw:'人',tip:'助理级从业人员需求人数',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '中级管理人员流失率',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '需求人数',dw:'人',tip:'中级管理人员需求人数',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '高级管理人员流失率',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '需求人数',dw:'人',tip:'高级管理人员需求人数',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
              ]
            },
            {
              id:'data7',
              label:'从业人员培养模式',
              des:'内部培训、外聘讲师培训、外部培训、管培生培训',
              values:[
                {label: '一线从业人员',dw:'',val:'',col:6,type:'peiyang'},
                {label: '助理级从业人员',dw:'',val:'',col:6,type:'peiyang'},
                {label: '中级管理人员',dw:'',val:'',col:6,type:'peiyang'},
                {label: '高级管理人员',dw:'',val:'',col:6,type:'peiyang'},
              ]
            },
            {
              id:'data8',
              label:'培训频次',
              des:'至少每月一次、至少每季度一次、至少每半年一次、至少每年一次',
              values:[
                {label: '一线从业人员',dw:'',val:'',col:6,type:'peixun'},
                {label: '助理级从业人员',dw:'',val:'',col:6,type:'peixun'},
                {label: '中级管理人员',dw:'',val:'',col:6,type:'peixun'},
                {label: '高级管理人员',dw:'',val:'',col:6,type:'peixun'},
              ]
            },
          ]
        },
        data_kehu:{
          title:'主要客户',
          datas:[
            {
              id:'data1',
              label:'主要客户',
              des:'列举5家，一行一个',
              values:[
                {label: '主要客户',dw:'',val:'',col:12,type:'textarea'},
              ]
            },
          ]
        },
        data_xinxixitong:{
          title:'信息系统投入金额',
          datas:[
            {
              id:'data1',
              label:'投入金额',
              values:[
                {label: '自主研发',dw:'万元',val:'0',col:12,rule:'money',rq:true},
                {label: '购买使用',dw:'万元',val:'0',col:12,rule:'money',rq:true},
              ]
            },
          ]
        },
        data_zidonghua:{
          title:'自动化设施设备投入金额',
          datas:[
            {
              id:'data1',
              label:'投入金额',
              values:[
                {label: '自主研发',dw:'万元',val:'0',col:12,rule:'money',rq:true},
                {label: '购买使用',dw:'万元',val:'0',col:12,rule:'money',rq:true},
              ]
            },
          ]
        }
      },

      companyInfo:null
    }
  },
  created() {
    this.formData.tid = this.$route.query.id ? this.$route.query.id : '';
  },
  mounted() {
    if(this.formData.tid) {
      this.getDetail();
      this.getCompany();
      // this.url = '/CompanyDataYiyao/edit';
    }else{
      // this.url = '/CompanyDataYiyao/add';

      this.initData();
    }
    // this.getPermission();
  },
  methods:{
    toDel(){
      let _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$http.httpPost({
          url: '/CompanyDataYiyao/del',
          datas: {id: this.formData.id,del:1},
          success(res) {
            _this.$utils.success("删除成功！");
            _this.getDetail();
          }
        });
      }).catch(() => {
        // _this.$utils.warning('取消了');
      });
    },
    initData(){
      this.formData.id = '';
      this.formData.data_col = JSON.parse(JSON.stringify(this.defData));
    },
    changeYear(e){
      this.year = e;
      this.formData.year = e;
      this.formData.id = '';

      this.getDetail();
    },
    getCompany(){
      let _this = this;

      this.$http.httpPost({
        url: '/Company/detail',
        datas: {id:this.formData.tid},
        success(res) {
          console.log(res);
          _this.companyInfo = res;
        }
      });
    },
    getDetail() {
      let _this = this;
      if(this.formData.year && this.formData.tid) {
        this.$http.httpPost({
          url: '/CompanyDataYiyao/detail',
          datas: {tid: this.formData.tid, year: this.formData.year},
          success(res) {
            // console.log(res);

            _this.initData();

            if (res) {
              _this.formData.id = res.id;
              _this.formData.tid = res.tid;
              _this.formData.year = res.year;

              for(let i in res){
                if(i.indexOf('data_') === 0){
                  if(res[i]) {
                    _this.formData.data_col[i] = res[i];
                  }
                }
              }
            }else{
              // _this.$refs.form.resetFields();
              _this.initData();
            }

            // if(_this.formData.province_code && _this.formData.province_name){
            //   _this.province = {code:_this.formData.province_code,name:_this.formData.province_name};
            // }
          }
        });
      }
    },
    create() {
      let _this = this;

      if(this.formData.id) {
        this.url = '/CompanyDataYiyao/edit';
      }else{
        this.url = '/CompanyDataYiyao/add';
      }

      this.$http.httpPost({
        url: this.url,
        datas: {...this.formData,...this.editData},
        success(res) {
          _this.$utils.success('保存成功！');
          _this.$router.back();
        }
      });
    },
    submitForm(formName) {
      //存在回调的方法，因为回调外和回调内的作用域不一致，所以务必为 _this 复制 this，否则在回调中无法访问this对象
      let _this = this;
      //通过传递form表单ref名称，来调用表单的validate方法
      this.$refs[formName].validate(valid => {
        //为true时验证通过
        if (valid) {
          _this.create();
        } else {
          _this.$nextTick(()=>{
            let _dom = document.getElementsByClassName('is-error');

            document.querySelector('.el-main').scrollTo({behavior:'smooth',top:_dom[0].offsetParent.offsetTop-60});
          })
          console.log("error submit!!");
          _this.$message.error('请检查表单');
          return false;
        }
      });
    },
  }
}
</script>
<style>
.el-input__inner{padding-right:0px !important;}
</style>