<template>
  <div>
    <div class="margin-top">
      <el-form :model="formData" :rules="rules" ref="form" :label-width="params.edit.labelWidth ? params.edit.labelWidth : '150px'">
        <el-form-item label="ID" v-if="formData && formData.id">
          {{formData.id}}
        </el-form-item>
        <el-form-item label="年份" prop="year" v-if="params.edit.chooseYear" :rules="[{required: true,message:'年份必填'}]">
          <year-select v-model="year" @change="changeYear" style="width: 500px;"></year-select>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="24 / (params.edit.cols ? params.edit.cols : 1)" v-for="(item,index) in editParams">
            <el-form-item :label="item.name" :prop="item.value">
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "edit"
}
</script>

<style scoped>

</style>