<template>
    <el-date-picker
        :editable="false"
        :clearable="clearable"
        type="date"
        placeholder="选择日期"
        v-model="values"
        style="width: 100%;"
        @change="change"></el-date-picker>
</template>

<script>
export default {
    name: "PushTime",
    data(){
        return{
            values:'',
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props: {
        value: {
            type:[String,Number],
            default: ''
        },
        clearable: {
            type:Boolean,
            default: false
        },
    },
    created() {
        this.values = this.value ? this.$moment(this.value).toDate() : '';
    },
    watch:{
        value(n,o){
            this.values = n ? this.$moment(n).toDate() : '';
        },
    },
    methods:{
        change(e){
            console.log(e);

            if(e) {
                let _year = e.getFullYear();
                let _month = e.getMonth() + 1;
                let _day = e.getDate();

                this.$emit('change', _year + '-' + _month + '-' + _day);
            }else{
                this.$emit('change','');
            }
        },
    }
}
</script>
