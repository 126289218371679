<template>
	<div class="bg-light padding-20">
		<div class="margin-bottom">
			<el-button @click="$router.back()" icon="el-icon-back">返回</el-button>
		</div>
		<div class="margin-top" v-if="info">
			<div class="dark-1 margin-bottom-20">问卷标题：{{ info.title }} </div>
			<div class="padding margin-bottom-20 border-w-1" v-for="(item,index) in info.content">
				<div class="dark-0 f16">
					{{ index + 1 }}、{{ item.title }}
					<span class="f12" v-if="item.type === 'radio'">[单选题]</span>
					<span class="f12" v-if="item.type === 'multi'">[多选题]</span>
					<span class="f12" v-if="item.type === 'input'">[填空题]</span>
				</div>
				<template v-if="item.type === 'radio' || item.type === 'multi'">
					<div class="margin-top f14 dark-2 padding" style="border:1px solid #008f29;" v-for="(st,si) in item.value_arr">
						<i class="el-icon-success success margin-right-5"></i>{{ st.title }}
					</div>
				</template>
				<template v-else>
					<div class="margin-top f14 dark-2 padding" style="border:1px solid #008f29;">
						{{item.value[0]}}
					</div>
				</template>
				<file-pre-view :list="item.files" class="margin-top margin-bottom" v-if="item.files && item.files.length > 0"></file-pre-view>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "detail",
	data() {
		return {
			id: '',
			info: null
		}
	},
	created() {
		this.id = this.$route.query.id ? this.$route.query.id : '';
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		getDetail() {
			let _this = this;
			
			this.$http.httpPost({
				url: '/SurveyLog/detail',
				datas: {id: this.id},
				success: (res) => {
					console.log(res);
					if (res) {
						_this.info = res;
						// _this.info.content = JSON.parse(res.content);
						
						console.log(_this.info);
					}
				}
			})
		}
	}
}
</script>
