import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/main.css'
import Moment from 'moment';
import XLSX from 'xlsx';

Vue.use(ElementUI);

import Config from './script/config';
import HTTPs from './script/http';
import Utils from './script/utils'
import User from './script/user';
import Rule from './script/rule';
import sideMenu from './script/sideMenu';
import RegExp from './script/RegExp';
import AliOSS from 'ali-oss';
import EchartsJs from './echartScript/echartsJs';
let echarts = require('echarts');

Vue.prototype.$config = Config;
Vue.prototype.$http = HTTPs;
Vue.prototype.$utils = Utils;
Vue.prototype.$user = User;
Vue.prototype.$rule = Rule;
Vue.prototype.$moment = Moment;
Vue.prototype.$sideMenu = sideMenu;
Vue.prototype.$xlsx = XLSX;
Vue.prototype.$regExp = RegExp;
Vue.prototype.$echarts = echarts;
Vue.prototype.$echartsJs = EchartsJs;
Vue.prototype.$AliOSS = AliOSS;

String.prototype.bool = function() {
  return (/^true$/i).test(this);
};

import VueUeditorWrap from 'vue-ueditor-wrap'
import UserNavMain from './components/common/UserNavMain';
import MainLayout from './components/common/MainLayout'
import SideMenu from './components/common/SideMenu';
import TypeSelect from "@/components/widget/TypeSelect";
import YearSelect from "@/components/widget/YearSelect";
import EditButton from "@/components/button/EditButton";
import DelButton from "@/components/button/DelButton";
import AddButton from "@/components/button/AddButton";
import BaseButton from "@/components/button/BaseButton";
import MainBreadcrumb from "@/components/common/MainBreadcrumb";
import HeadImgUpload from "@/components/widget/HeadImgUpload";
import PushTime from "@/components/widget/PushTime";
import CompanySelect from "@/components/widget/CompanySelect";
import FileUpload from "@/components/widget/FileUpload";
import RangeTime from "@/components/widget/RangeTime";
import PriceTimeSelect from "@/components/widget/PriceTimeSelect";
import PriceVip from "@/components/widget/PriceVip";
import BlockVip from "@/components/widget/BlockVip";
import PutInt from "@/components/widget/PutInt";
import ProvinceSelect from "./components/widget/ProvinceSelect";
import UEditor from "@/components/UEditor";
import YearMonthSelect from "@/components/widget/YearMonthSelect";
import CleanUEditor from "@/components/CleanUEditor";
import BarEcharts from "@/components/widget/BarEcharts";
import Tags from './components/widget/Tags'
import ImageUpload from './components/widget/ImageUpload'
import SpeakerSelect from './components/widget/SpeakerSelect'
import FilePreView from './components/widget/FilePreView'
import ImagePreView from './components/widget/ImagePreView'

Vue.component("edit-button", EditButton);
Vue.component("del-button", DelButton);
Vue.component("add-button", AddButton);
Vue.component("base-button", BaseButton);

Vue.component('m-main-layout',MainLayout);
Vue.component('m-side-menu',SideMenu);
Vue.component('m-user-nav-main',UserNavMain);
Vue.component('editor',UEditor);
Vue.component('clean-editor',CleanUEditor);
Vue.component('type-select',TypeSelect);
Vue.component('company-select',CompanySelect);
Vue.component('year-select',YearSelect);
Vue.component('m-breadcrumb',MainBreadcrumb);
Vue.component('head-img-upload',HeadImgUpload);
Vue.component('push-time',PushTime);
Vue.component('file-upload',FileUpload);
Vue.component('range-time',RangeTime);
Vue.component('price-time-select',PriceTimeSelect);
Vue.component('price-vip',PriceVip);
Vue.component('block-vip',BlockVip);
Vue.component('put-in',PutInt);
Vue.component('province-select',ProvinceSelect);
Vue.component('vue-ueditor-wrap',VueUeditorWrap);
Vue.component('year-month-select',YearMonthSelect)
Vue.component('bar-echarts', BarEcharts);
Vue.component('tags', Tags);
Vue.component('image-upload', ImageUpload);
Vue.component('speaker-select', SpeakerSelect);
Vue.component('image-pre-view', ImagePreView);
Vue.component('file-pre-view', FilePreView);

router.beforeEach((to, from, next) => {
  let _crossList = ['/login'];
  if(_crossList.indexOf(to.path) === -1){
    if(!User.getToken()){
      next({ path: '/login' })
    }else{
      next();
    }
  }else{
    next();
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
