<template>
  <div v-if="list.length > 0">
    <div class="margin-bottom cl">
      <add-button @click.native="$router.push({path:'/user/vip/type/edit'})" class="z"></add-button>
      <el-form :model="formData" :rules="rules" class="z margin-left" inline size="mini" ref="form">
        <el-form-item label="注册用户浏览次数" prop="user_view_count">
          <el-input v-model="formData.user_view_count"></el-input>
        </el-form-item>
        <el-form-item label="注册用户下载次数" prop="user_down_count">
          <el-input v-model="formData.user_down_count"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" default-expand-all style="width: 100%" row-key="id" :tree-props="{children: 'child'}" :indent="30" v-if="list.length > 0">
      <el-table-column
        prop="title"
        label="描述" width="300"></el-table-column>
      <el-table-column
        prop="old_price"
        label="原价">
        <template slot-scope="scope">
          {{scope.row.pid !== '0' ? scope.row.old_price : ''}}
        </template>
      </el-table-column>
      <el-table-column
        prop="price"
        label="现价">
        <template slot-scope="scope">
          {{scope.row.pid !== '0' ? scope.row.price : ''}}
        </template>
      </el-table-column>
      <el-table-column
        prop="totime"
        label="时长">
        <template slot-scope="scope" v-if="scope.row.pid !== '0'">
          <div v-if="scope.row.time_type === 'day'">{{scope.row.to_time}}天</div>
          <div v-if="scope.row.time_type === 'week'">{{scope.row.to_time}}周</div>
          <div v-if="scope.row.time_type === 'month'">{{scope.row.to_time}}月</div>
          <div v-if="scope.row.time_type === 'year'">{{scope.row.to_time}}年</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="view_count"
        label="浏览次数">
        <template slot-scope="scope">
              <span v-if="scope.row.pid === '0'">
                <span v-if="scope.row.view_count === -1">禁止</span>
                <span v-else-if="scope.row.view_count === 0">不限</span>
                <span v-else>{{scope.row.view_count}}</span>
              </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="down_count"
        label="下载次数">
        <template slot-scope="scope">
              <span v-if="scope.row.pid === '0'">
                <span v-if="scope.row.down_count === -1">禁止</span>
                <span v-else-if="scope.row.down_count === 0">不限</span>
                <span v-else>{{scope.row.down_count}}</span>
              </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_super"
        label="超级VIP">
        <template slot-scope="scope">
              <span v-if="scope.row.pid === '0'">
                <span v-if="scope.row.is_super === 1">是</span>
                <span v-if="scope.row.is_super === 0">否</span>
              </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="sort"
        label="排序">
      </el-table-column>
      <el-table-column
        prop="state"
        label="状态">
        <template slot-scope="scope">
          <el-tag :type="scope.row.state === 1 ? 'success' : 'danger'" disable-transitions>{{scope.row.state === 0 ? '禁用' : '可用'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="300" align="right">
        <template slot-scope="scope">
          <base-button @click.native="addCol(scope.row.id)" icon="el-icon-s-fold" v-if="scope.row.pid === '0'">添加价格</base-button>
          <edit-button @click.native="$router.push({path:'/user/vip/edit',query:{id:scope.row.id}})" v-if="scope.row.pid !== '0'"></edit-button>
          <edit-button @click.native="$router.push({path:'/user/vip/type/edit',query:{id:scope.row.id}})" v-else></edit-button>
          <del-button @click.native="del(scope.row.id)"></del-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="padding-top-bottom-40 border-top text-center dark-2" v-else>
    暂无数据，<add-button @click.native="$router.push({path:'/user/vip/type/edit'})"></add-button>
  </div>
</template>

<script>
export default {
  name: "vip",
  data(){
    return{
      list:[],
      // id:'',
      info:null,
      formData:{
        user_view_count:0,
        user_down_count:0
      },
      rules:{
        user_view_count:[{required:true,message:'注册用户浏览次数必填'},{pattern:this.$regExp.positiveInteger,message:'只能填写正整数'}],
        user_down_count:[{required:true,message:'注册用户下载次数必填'},{pattern:this.$regExp.positiveInteger,message:'只能填写正整数'}]
      }
    }
  },
  created() {
    // this.id = this.$route.query.id ? this.$route.query.id : '';
  },
  mounted() {
    this.getList();
    this.getSettingDetail();
  },
  methods:{
    getList(){
      let _this = this;

      _this.$http.httpPost({
        url: '/VipPrice/listDigui',
        datas: null,
        success: (res) => {
          _this.list = res;
        }
      })
    },
    getDetail(){
      let _this = this;

      if(this.id) {
        _this.$http.httpPost({
          url: '/SurveyTitle/detail',
          datas: {id:this.id},
          success: (res) => {
            _this.info = res;
          }
        })
      }
    },

    getSettingDetail() {
      let _this = this;

      let _skeys = [];

      for(let k in this.formData){
        _skeys.push(k);
      }

      _this.$http.httpPost({
        url:'/SystemSetting/getSystem',
        datas:{skey:_skeys},
        success:(res)=>{
          _this.formData = res;
        }
      });
    },

    submitForm() {
      let _this = this;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          _this.$http.httpPost({
            url:'/SystemSetting/edit',
            datas:_this.formData,
            success:(res)=>{
              if(res === 'ok'){
                _this.$message.success('保存成功');
              }else{
                _this.$message.error('保存失败');
              }
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    addCol(pid = 0){
      this.$router.push({path:'/user/vip/edit',query:{pid:pid}});
    },
    editCol(id){
      this.$router.push({path:'/user/vip/edit',query:{id:id}});
    },
    del(id) {
      var _this = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.$http.httpPost({
          url: '/VipPrice/del',
          datas: {id: id,del:1},
          success: (res) => {
            _this.getList();
          }
        });
      }).catch(() => {
      });
    },
  }
}
</script>
