<template>
	<div class="bg-light padding-20">
		<div class="cl margin-bottom">
			<add-button label="添加调查问卷" @click.native="onShowDialog('',null)"></add-button>
		</div>
		<div class="bg-light border-w-1 margin-bottom-20" v-for="(item,index) in list">
			<div class="cl margin padding-bottom border-bottom">
				<div class="z">{{item.title}}</div>
				<div class="y f14 dark-3">
					ID：{{item.id}}<span class="padding-left-right">|</span>
					状态：<span class="dark-3" v-if="item.state === 0">未发布</span>
					<span class="success" v-if="item.state === 1">发布中</span>
					<span class="danger" v-if="item.state === 2">已结束</span><span class="padding-left-right">|</span>
					问题数量：{{item.question_count}}<span class="padding-left-right">|</span>
					答卷数量：<a class="hover-under" @click="$router.push({path:'/survey/log',query:{id:item.id}})">{{item.join_count}}</a><span class="padding-left-right">|</span>
					创建时间：{{item.create_time}}
				</div>
			</div>
			<div class="cl padding">
				<div class="z">
					<div class="f14 dark-3 margin-bottom" v-if="item.clean_des" v-html="item.clean_des">说明：{{item.clean_des}}</div>
					<el-button size="mini" @click="toResult(item)">查看结果</el-button>
				</div>
				<div class="y">
					<base-button icon="el-icon-video-pause" v-if="item.state === 1" @click.native="toChangeState(item.id,2)">结束</base-button>
					<base-button type="success" icon="el-icon-caret-right" :disabled="item.question_count <= 0" v-if="item.state === 0" @click.native="toChangeState(item.id,1)">发布</base-button>
					<base-button type="info" icon="el-icon-caret-bottom" v-if="item.state === 1" @click.native="toChangeState(item.id,0)">停用</base-button>
					<edit-button @click.native="onShowDialog(item.id,item)"></edit-button>
					<base-button type="danger" icon="el-icon-s-operation" @click.native="toCol(item.id)">选项</base-button>
					<del-button @click.native="toDel(item.id)" class="margin-right" :disabled="item.state === 1"></del-button>
					<el-popover
						placement="right"
						width="300"
						trigger="click">
						<img :src="qrCode" width="276" v-if="qrCode"/>
						<div class="text-center" style="line-height: 276px;" v-else>
							<i class="el-icon-loading f20"></i>
						</div>
						<base-button type="success" class="margin-right" slot="reference" icon="el-icon-menu" @click.native="getQrCode(item.id)">二维码</base-button>
					</el-popover>
					<base-button icon="el-icon-download" @click.native="toExportExcel(item)">导出Excel</base-button>
					<base-button icon="el-icon-download" @click.native="toExportWord(item)">导出Word</base-button>
				</div>
			</div>
		</div>
		<div class="padding-top-bottom-20 text-center">
			<el-pagination
				background
				layout="total,prev, pager, next"
				:page-size="searchForm.pageSize"
				:current-page.sync="searchForm.page"
				:total="totalCount"
				@current-change="pageChange">
			</el-pagination>
		</div>
		<el-dialog title="添加选项" :visible.sync="dialogVisible" @closed="onCloseDialog" append-to-body>
			<el-form :model="formData" label-width="150px" ref="form" :rules="rules">
				<el-form-item prop="title" label="问卷标题">
					<el-input v-model="formData.title" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item prop="is_only" label="是否答卷一次">
					<el-radio-group v-model="formData.is_only">
						<el-radio :label="0">否</el-radio>
						<el-radio :label="1">是</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item prop="des_html" label="问卷说明">
					<clean-editor v-model="formData.des_html" ref="editor" style="width:100%;"></clean-editor>
					<!--          <el-input type="textarea" v-model="formData.des" autocomplete="off"></el-input>-->
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="toAddTitle">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "list",
	data() {
		return {
			list:[],
			
			searchForm:{
				page:1,
				pageSize:10
			},
			totalCount:0,
			
			dialogVisible:false,
			
			formData:{
				id:'',
				title:'',
				des_html:'',
				state:0,
				is_only:0
			},
			
			rules: {
				title:[
					{required:true,message:'内容必填'}
				]
			},
			
			qrCode:''
		}
	},
	created() {
	},
	mounted() {
		this.getList();
	},
	methods: {
		toExportExcel(row){
			let _this = this;
			
			_this.$http.httpPost({
				download:true,
				url: '/SurveyLog/exportExcel',
				datas: {tid:row.id},
				success: (res) => {
					const blob = new Blob([res]) // 构造一个blob对象来处理数据
					let num = _this.$moment().format('YYYYMMDD');
					
					const fileName = row.title + '_' + num + '.xlsx';
					const link = document.createElement('a') // 创建a标签
					link.download = fileName // a标签添加属性
					link.style.display = 'none'
					link.href = URL.createObjectURL(blob)
					document.body.appendChild(link)
					link.click();
					URL.revokeObjectURL(link.href);
					document.body.removeChild(link);
					_this.$utils.success("操作成功！");
				}
			})
		},
		toExportWord(row){
			let _this = this;
			
			_this.$http.httpPost({
				download:true,
				url: '/SurveyLog/exportWord',
				datas: {tid:row.id},
				success: (res) => {
					const blob = new Blob([res]) // 构造一个blob对象来处理数据
					let num = _this.$moment().format('YYYYMMDD');
					
					const fileName = row.title + '_' + num + '.docx';
					const link = document.createElement('a') // 创建a标签
					link.download = fileName // a标签添加属性
					link.style.display = 'none'
					link.href = URL.createObjectURL(blob)
					document.body.appendChild(link)
					link.click();
					URL.revokeObjectURL(link.href);
					document.body.removeChild(link);
					_this.$utils.success("操作成功！");
				}
			})
		},
		getQrCode(id){
			let _this = this;
			
			_this.$http.httpPost({
				url: '/SurveyTitle/getQrCode',
				datas: {id:id},
				success: (res) => {
					console.log(res);
					_this.qrCode = res;
				}
			})
		},
		toResult(e){
			this.$router.push({path:'/survey/result',query:{id:e.id}})
		},
		pageChange(e){
			this.searchForm.page = e;
			this.getList();
		},
		onCloseDialog(){
			this.formData.id = '';
			this.formData.title = '';
			this.formData.des_html = '';
			this.formData.state = 0;
			this.formData.is_only = 0;
			
			this.dialogVisible = false;
		},
		onShowDialog(id = '',item = null){
			this.dialogVisible = true;
			
			this.$nextTick(()=>{
				if(item){
					this.formData.id = item.id;
					this.formData.title = item.title;
					this.formData.des_html = item.des_html;
					this.formData.state = item.state;
					this.formData.is_only = item.is_only;
				}
			})
		},
		
		toAddTitle(){
			let _this = this;
			
			this.$refs['form'].validate((valid) => {
				if (valid) {
					_this.$http.httpPost({
						url: _this.formData.id ? '/SurveyTitle/edit' : '/SurveyTitle/add',
						datas: {..._this.formData},
						success: (res) => {
							_this.$message.success('添加成功');
							_this.getList();
							_this.onCloseDialog();
						}
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		toCol(id){
			this.$router.push({path:'/survey/col/add',query:{id:id}});
		},
		toDel(id){
			var _this = this;
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.httpPost({
					url: '/SurveyTitle/del',
					datas: {id: id, del: 1},
					success: (res) => {
						_this.getList();
					}
				});
			}).catch(() => {
			});
		},
		toChangeState(id,state){
			let _this = this;
			
			let _title = '';
			
			if(state === 0){
				_title = '是否停用此调查问卷？';
			}else if(state === 1){
				_title = '是否发布此调查问卷？';
			}else if(state === 2){
				_title = '是否结束此调查问卷？';
			}
			
			this.$confirm(_title, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.httpPost({
					url: '/SurveyTitle/changeState',
					datas:{id:id,state:state},
					success: (res) => {
						console.log(res);
						_this.$message.success('操作成功');
						
						_this.getList();
					}
				})
			}).catch(() => {});
		},
		getList(){
			let _this = this;
			
			_this.$http.httpPost({
				url: '/SurveyTitle/listPage',
				datas:this.searchForm,
				success: (res) => {
					console.log(res);
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			})
		},
		addTitle(id = 0,pid=0){
			let _this = this;
			
			let _title = '';
			
			if(id){
				_title = '选项';
			}else{
				_title = '问题';
			}
			
			this.$prompt('请输入'+_title, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputValidator(value){
					if(value && value.trim()){
						return true;
					}else {
						return false;
					}
				},
				inputErrorMessage: _title+'内容必填'
			}).then(({ value }) => {
				_this.$http.httpPost({
					url: '/SurveyTitle/add',
					datas: {title:value,tid:_this.id,pid:pid ? pid : 0},
					success: (res) => {
						_this.$message.success('添加成功');
						_this.getList();
					}
				})
			}).catch(() => {
			});
		}
	}
}
</script>
