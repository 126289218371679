<template>
	<div class="bg-light padding">
		<el-form :model="formData" label-width="150px" ref="form" :rules="rules">
			<el-form-item prop="title" label="标题">
				<el-input v-model="formData.title" maxlength="30" show-word-limit></el-input>
			</el-form-item>
			<el-form-item prop="html_content" label="内容">
				<editor v-model="formData.html_content" ref="editor" style="width:100%;"/>
			</el-form-item>
			<el-form-item>
				<el-button @click="$router.back()">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "articledetail",
	data() {
		return {
			info:null,
			totalCount: 0,
			formData: {
				id:'',
				extid:'',
				html_content:'',
				title:''
			},
			rules:{
				title:[{required:true,message:'标题不能为空'}],
				html_content:[{required:true,message:'内容不能为空'}],
			}
		}
	},
	created() {
		this.formData.extid = this.$route.query.id ? this.$route.query.id : ''
	},
	mounted() {
		if(this.formData.extid){
			this.getDetail();
		}
	},
	methods: {
		getDetail(){
			let _this = this;
			
			_this.$http.httpPost({
				url: '/Article/detail',
				datas: {extid:this.formData.extid},
				success: (res) => {
					if(res) {
						_this.info = res;
						_this.formData.id = res.id;
						_this.formData.html_content = res.html_content;
						_this.formData.title = res.title;
					}
				}
			})
		},
		submit(){
			let _this = this;
			let _url = '';
			if(this.info){
				_url = '/Article/edit';
			}else{
				_url = '/Article/add';
			}
			this.$refs['form'].validate((valid) => {
				if (valid) {
					_this.$http.httpPost({
						url: _url,
						datas: _this.formData,
						success: (res) => {
							_this.$alert('保存成功', '提示', {
								confirmButtonText: '确定',
								closeOnClickModal:false,
								closeOnPressEscape:false,
								showClose:false,
								callback: action => {
									_this.$router.back();
								}
							});
						}
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		}
	}
}
</script>
