<template>
    <div>
        <div class="page-content-main">
<!--            <el-form :model="searchForm" ref="searchForm">-->
<!--                <el-row :gutter="10">-->
<!--                    <el-col :span="4">-->
<!--                        <el-form-item label="订单号：">-->
<!--                            <el-input v-model="searchForm.order_no"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="4">-->
<!--                        <el-form-item label="交易单号：">-->
<!--                            <el-input v-model="searchForm.out_trade_no"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="4">-->
<!--                        <el-form-item label="收件人">-->
<!--                            <el-input v-model="searchForm.consignee"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="4">-->
<!--                        <el-form-item label="收件人电话">-->
<!--                            <el-input v-model="searchForm.phone"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="4">-->
<!--                        <el-form-item label="订单状态">-->
<!--                            <el-select v-model="searchForm.state">-->
<!--                                <el-option label="-全部-" value=""></el-option>-->
<!--                                <el-option label="已关闭" value="0"></el-option>-->
<!--                                <el-option label="待付款" value="1"></el-option>-->
<!--                                <el-option label="已付款待发货" value="2"></el-option>-->
<!--                                <el-option label="已发货" value="3"></el-option>-->
<!--&lt;!&ndash;                                <el-option label="待评价" value="4"></el-option>&ndash;&gt;-->
<!--&lt;!&ndash;                                <el-option label="已评价" value="5"></el-option>&ndash;&gt;-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                </el-row>-->
<!--                <el-button type="primary" @click="toSearch">查询</el-button>-->
<!--                <el-button @click="clearSearch">清空</el-button>-->
<!--            </el-form>-->
            <el-table :data="dataList" default-expand-all style="width: 100%" row-key="id"
                      :tree-props="{children: 'child'}" :indent="30" v-if="dataList.length > 0">
                <el-table-column
                    prop="content"
                    label="评论内容"></el-table-column>
                <el-table-column
                    prop="akey"
                    label="评论模块">
                    <template slot-scope="scope">
                        <div v-if="scope.row.akey === 'industry'">行业发展报告</div>
                        <div v-if="scope.row.akey === 'domain'">细分领域报告</div>
                        <div v-if="scope.row.akey === 'policy'">政策</div>
                        <div v-if="scope.row.akey === 'standard'">标准</div>
                        <div v-if="scope.row.akey === 'news'">新闻资讯</div>
                        <div v-if="scope.row.akey === 'prize'">权威榜单</div>
                        <div v-if="scope.row.akey === 'park'">产业园区</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="nickname"
                    label="评论人">
                </el-table-column>
                <el-table-column
                    prop="state"
                    label="状态" style="font-size:12px;" width="150">
                    <template slot-scope="scope">
                        <el-tag type="danger" disable-transitions v-if="scope.row.state === 0">待审核</el-tag>
                        <el-tag type="success" disable-transitions v-if="scope.row.state === 1">已通过</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="create_time"
                    label="下单时间"></el-table-column>
                <el-table-column align="right">
                    <template slot-scope="scope">
                        <base-button @click.native="changeState(scope.row.id,1)" v-if="scope.row.state === 0" type="success">审核通过</base-button>
                        <base-button @click.native="changeState(scope.row.id,0)" v-if="scope.row.state === 1" type="danger">审核拒绝</base-button>
                        <del-button @click.native="del(scope.row.id)" type="danger"></del-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text-center null-data f14 info" v-else>
                还没有数据
            </div>
            <div class="text-center padding-top-bottom-20" v-if="dataList.length > 0">
                <el-pagination
                    background
                    layout="total,prev, pager, next"
                    :page-size="pageSize"
                    :current-page.sync="searchForm.page"
                    :total="totalCount"
                    @current-change="pageChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MemberList",
    data() {
        return {
            searchForm: {
                state:'',
                type:'reply',
                page:1
            },
            dataList: [],
            totalPage: 0,
            totalCount: 0,
            pageSize: 20,
            page: 1,
        }
    },
    created() {
        let _query = this.$route.query;
        this.searchForm = {...this.searchForm,..._query};
        this.searchForm.page = _query.page ? parseInt(_query.page) : 1;
    },
    methods: {
        toSearch() {
            this.searchForm.page = 1;
            this.$router.replace({query:this.searchForm});
            this.getList();
        },
        clearSearch() {
            this.searchForm = {
                order_no:'',
                out_trade_no:'',
                phone:'',
                state:'',
                order_type:'',
                consignee:'',
                page:1
            };
            // this.page = 1;
            this.$router.replace({query:this.searchForm});
            this.getList();
        },
        getList() {
            let _this = this;
            this.dataList = [];
            this.$http.httpPost({
                url: '/UserLog/listPage',
                datas: {...this.searchForm,pageSize: this.pageSize},
                success: (res) => {
                    _this.dataList = res.list;
                    _this.totalPage = res.totalPage;
                    _this.totalCount = res.totalCount;
                }
            });
        },
        changeState(id, state) {
            var _this = this;
            this.$confirm('是否改变该条数据的状态?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$http.httpPost({
                    url:'/UserLog/state',
                    datas:{id:id,state:state},
                    success:(res)=>{
                        _this.$utils.success('操作成功');
            	          _this.getList();
                    }
                });
            }).catch(() => {
            });
        },
        del(id, state) {
            var _this = this;
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$http.httpPost({
                    url:'/UserLog/del',
                    datas:{id:id,del:1},
                    success:(res)=>{
                        _this.$utils.success('操作成功');
                        _this.getList();
                    }
                });
            }).catch(() => {
            });
        },
        pageChange(page) {
            this.searchForm.page = page;
            this.$router.replace({query:this.searchForm})
        },
    },
    mounted() {
        this.getList();
    }
}
</script>
<style scoped>
.el-select,.el-range-editor{width:100%;}
</style>
