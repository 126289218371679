<template>
	<div class="bg-light padding-20">
    <el-form
        :model="formData"
        status-icon
        :rules="rules"
        ref="form"
        label-width="auto"
        class="margin-top"
        style="width:800px;"
    >
        <el-form-item label="微信服务号APPID" prop="weixin_service_app_id">
            <el-input v-model="formData.weixin_service_app_id" style="width:500px;">
            </el-input>
        </el-form-item>
        <el-form-item label="微信服务号AppSecret" prop="weixin_service_app_secret">
            <el-input v-model="formData.weixin_service_app_secret" style="width:500px;">
            </el-input>
        </el-form-item>
        <el-form-item label="微信服务号MsgToken" prop="weixin_service_msg_token">
            <el-input v-model="formData.weixin_service_msg_token" style="width:500px;">
            </el-input>
        </el-form-item>
        <el-form-item label="微信服务号MsgSecret" prop="weixin_service_msg_secret">
            <el-input v-model="formData.weixin_service_msg_secret" style="width:500px;">
            </el-input>
        </el-form-item>
        <el-form-item label="微信服务号号二维码" prop="weixin_service_qrcode">
            <head-img-upload v-model="formData.weixin_service_qrcode"></head-img-upload>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('form')">确定</el-button>
            <el-button type="" @click="$router.back()">返回</el-button>
        </el-form-item>
    </el-form>
	</div>
</template>

<script>
export default {
    name: "add",
    data() {
        return {
            formData: {
                weixin_service_app_id: "",
                weixin_service_app_secret: "",
                weixin_service_msg_token: "",
                weixin_service_msg_secret: "",
                weixin_service_qrcode:''
            },
            rules: {
                weixin_service_app_id: [{required: true,message:'服务号appId必填'}],
                weixin_service_app_secret: [{required: true,message:'服务号appSecret必填'}],
                weixin_service_qrcode: [{required: true,message:'服务号二维码必填'}],
            }
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        submitForm(formName) {
            let _this = this;
            console.log(_this.formData);
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.httpPost({
                        url: "/SystemSetting/edit",
                        datas: {..._this.formData},
                        success: res => {
                            if (res === "ok") {
                                _this.$message.success("修改成功");
                            } else {
                                _this.$message.error("修改失败");
                            }
                            // _this.$message.info(res.msg);
                            // _this.$router.replace({path: '/cate/list'})
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        getDetail() {
            let _this = this;

            let _skeys = [];

            for (let k in this.formData) {
                _skeys.push(k);
            }

            _this.$http.httpPost({
                url: "/SystemSetting/getSystem",
                datas: {skey: _skeys},
                success: res => {
                    _this.formData = res;
                }
            });
        }
    }
};
</script>
