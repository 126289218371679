<template>
	<el-select
		v-model="values"
		:multiple="false"
		clearable
		filterable
		remote
		reserve-keyword
		placeholder="请输入关键词"
		:remote-method="toSearch"
		@change="onChange"
		:loading="loading">
		<el-option
			v-for="item in list"
			:key="item.id"
			:label="item.name"
			:value="item.id">
		</el-option>
	</el-select>
</template>

<script>
export default {
	name: "SpeakerSelect",
	data() {
		return {
			list: [],
			values: '',
			searchForm: {
				name: ''
			},
			loading:false
		}
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		value: {
			type: [Array, String],
			default:''
		},
		canChoose: {
			type: Boolean,
			default: false
		},
		datas: {
			type: [Array, Object],
			default() {
				return {}
			}
		},
	},
	created() {
		this.values = this.value ? this.value : '';
	},
	watch: {
		value(n, o) {
			console.log(n);
			this.values = n ? n : '';
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		toSearch(query) {
			this.loading = true;
			this.searchForm.name = query;
			this.getList();
		},
		getList() {
			let _this = this;
			
			this.$http.httpPost({
				url: '/Speaker/listAll',
				datas: {state: 1, delete_time: 0,...this.searchForm},
				success(res) {
					_this.loading = false;
					_this.list = res;
				}
			});
		},
		onChange(e){
			this.$emit('change',this.values)
		}
	}
}
</script>
