<template>
    <div>
	    <el-upload
		    ref="upload"
		    v-if="remote === 'oss'"
		    style="width: 500px;"
		    class="upload-demo"
		    :action="$config.uploadUrl"
		    :data="{key:imgKey}"
		    :headers="{Authorization:$user.getToken()}"
		    :on-preview="onPreview"
		    :on-remove="onRemove"
		    :on-success="onSuccess"
		    :before-remove="beforeRemove"
		    :before-upload="beforeUpload"
		    :on-progress="onProgress"
		    :http-request="httpRequest"
		    :accept="accept"
		    :multiple="multiple"
		    :limit="limit"
		    :file-list="fileList">
		    <template slot-scope="{file}" slot="file">
			    <a class="el-upload-list__item-name" @click="onPreview(file)">
				    <i class="el-icon-document"></i>{{file.name}}
			    </a>
			    <label class="el-upload-list__item-status-label">
				    <i class="el-icon-upload-success el-icon-circle-check"></i>
			    </label>
			    <i class="el-icon-close" v-if="!disabled" @click="beforeRemoveOss(file)"></i>
			    <i class="el-icon-close-tip" v-if="!disabled">按 delete 键可删除</i> <!--因为close按钮只在li:focus的时候 display, li blur后就不存在了，所以键盘导航时永远无法 focus到 close按钮上-->
			    <el-progress
				    v-if="file.status === 'uploading' || file.status === 'ready'"
				    :type="'line'"
				    :stroke-width="2"
				    :percentage="file.percentage">
			    </el-progress>
		    </template>
		    <div class="text-left">
			    <template v-if="limit <= 1">
				    <el-button size="small" type="primary" v-if="fileList.length <= 0">点击上传</el-button>
			    </template>
			    <el-button size="small" type="primary" v-else>点击上传</el-button>
<!--			    {{accept}}{{remote}}{{fileList}}-->
			    <div class="dark-3"><template v-if="accept">支持格式：{{accept}}；</template>最大不能超过{{maxsize}}Mb；</div>
		    </div>
	    </el-upload>
	    <el-upload
		    v-else
		    style="width: 500px;"
		    class="upload-demo"
		    :action="$config.uploadUrl"
		    :data="{key:imgKey}"
		    :headers="{Authorization:$user.getToken()}"
		    :on-preview="onPreview"
		    :on-remove="onRemove"
		    :on-success="onSuccess"
		    :before-remove="beforeRemove"
		    :before-upload="beforeUpload"
		    :on-progress="onProgress"
		    :accept="accept"
		    :multiple="multiple"
		    :limit="limit"
		    :file-list="fileList">
		    <div class="text-left">
			    <!--	        {{fileList}}-->
			    <template v-if="limit <= 1">
				    <el-button size="small" type="primary" v-if="fileList.length <= 0">点击上传</el-button>
			    </template>
			    <el-button size="small" type="primary" v-else>点击上传</el-button>
<!--			    {{accept}}{{remote}}{{fileList}}-->
			    <!--            <div>格式：doc,docx,ppt,pdf,pptx,xls,xlsx,zip,rar,7z,rz,tar,zipx；最大不能超过20Mb；</div>-->
			    <div><template v-if="accept">支持格式：{{accept}}</template>最大不能超过{{maxsize}}Mb；</div>
		    </div>
	    </el-upload>
    </div>
</template>

<script>
export default {
    name: "FileUpload",
    data() {
        return {
            fileList:[],
            values:[],
            imgKey:'file',
            ids:[],
	        oss:null,
	        tmpFileList:[],
	        disabled:false
        };
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
			accept:{
				type:String,
				default:'.doc,.docx,.ppt,.pdf,.pptx,.xls,.xlsx,.zip,.rar,.7z,.rz,.tar,.zipx'
			},
        files:{
            type:[Array,String],
            default(){
                return []
            }
        },
        value:{
            type:[Array,String],
            default(){
                return []
            }
        },
        limit:{
            type:Number,
            default:10
        },
	    multiple:{
            type:Boolean,
            default:true
        },
	    maxsize:{
				type:Number,
		    default:20
	    },
	    remote: {
		    type: String,
		    default: 'local'
	    },
	    pathPre: {
		    type: String,
		    default: 'zhuanti'
	    },
    },
    created() {
        // this.values = this.files;
        this.fileList = this.files ? (typeof this.files === 'string' ? [{uid:this.$utils.randomWords(),url:this.files,name:this.files}] : this.files) : [];
				console.log(this.fileList);
        this.ids = this.value ? this.value : [];
	
	    if(this.remote === 'oss'){
		    this.oss = new this.$AliOSS({
			    // region: "oss-cn-hangzhou",
			    // endpoint: "oss-cn-hangzhou.aliyuncs.com",
			    // accessKeyId: "LTAI5tKGGHyFjkhBcafy9kK8",
			    // accessKeySecret: "NorzKn5xwd8HZHlZPSK0P6wM7uqKnL",
			    // bucket: "zpjiaxiao",
			    region: "oss-cn-beijing",
			    endpoint: "oss-cn-beijing.aliyuncs.com",
			    accessKeyId: "LTAI4Fyhy3L6QYW2vdUkXjKa",
			    accessKeySecret: "McgekyOemxDVckmRN3nxDJFY4ryEJI",
			    bucket: "chaincloud",
			    timeout: 10800000
		    })
	    }
    },
    watch:{
        files(n,o){
            console.log(n);
            // this.values = n;
            // this.fileList = n ? n : [];
            this.fileList = n ? (typeof n === 'string' ? [{uid:this.$utils.randomWords(),url:n,name:n}] : n) : [];

            // if(!n){
            //     this.fileList = [];
            // }
        },
        value(n,o){
            console.log(n);
            this.ids = n ? n : [];
        },
    },
    methods: {
	    onProgress(event, file, fileList){
				// console.log(event, file, fileList,'=d=d=d=');
	    },
	    httpRequest(file){
				let _this = this;
		    // console.log(file.file.name.split('.'));
		
		    let _path = '/'+this.pathPre+'/';
		
		    let _arr = file.file.name.split('.');
		
		    //获取后缀名
		    let _ext = _arr[_arr.length - 1];
		
		    _path += this.$moment().format('YYYY/MM/DD')+'/';
		
		    _path += this.$utils.randomWords(10);
		    _path += '.'+_ext;
		
		    const options = {
			    // 获取分片上传进度、断点和返回值。
			    progress: (p, cpt, res) => {
				    let _percent = file.percentage = parseInt(p * 100)
						// console.log(p,'111111111=')
				    file.file.status = 'uploading'
				    this.$refs.upload.handleProgress({percent:_percent},file.file);
				    // that.uploadId = cpt.uploadId;//取消时需要的参数
				    // that.progress = Number((p * 100).toFixed(0));//进度条
			    },
			    // 设置并发上传的分片数量。
			    parallel: 9999,
			    // 设置分片大小。默认值为1 MB，最小值为100 KB。
			    partSize: 1024 * 1024,
			    // headers,
			    // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
			    meta: { year: 2020, people: "test" },
			    contentType:false,
			    headers:{'Content-Type':file.file.type}
		    };
		    
				this.oss.multipartUpload(_path,file.file,options).then((res)=>{
					// console.log(res);
				  // _this.values.push(res.url);
				  // _this.fileList.push(res);
					let _res = {
						// uid:res.res.rt.toString(),
						name:file.file.name,
						url:this.$config.ossUrl + res.name
					}
					
					_this.values.push(_res.url)
					// _this.fileList.push(_res)
					// console.log(_res);
					// console.log(_this.fileList);

				  if(_this.limit > 1) {
				    _this.$emit('change', _this.values);
				  }else{
				    _this.$emit('change', _this.values[0]);
				  }
				}).catch((err) => {
				  console.log(err);
				})
		    // this.oss.put(_path, file.file,{
		    //   progress(p, cpt, res){
				// 		console.log(p, cpt, res)
				// 	}
		    // }).then((res) => {
				// 	console.log(res);
			  //   _this.values.push(res.url);
			  //   _this.fileList.push(res);
		    //
			  //   if(_this.limit > 1) {
				//     _this.$emit('change', _this.values);
			  //   }else{
				//     _this.$emit('change', _this.values[0]);
			  //   }
		    //
		    // }).catch((err) => {
			  //   console.log(err);
		    // })
	    },
        onRemove(file, fileList) {
            // this.values = fileList;
            this.fileList = fileList;

            this.ids = [];

            if(fileList.length > 0){
                for(let i=0;i < fileList.length;i++){
                    if(fileList[i].id){
                        this.ids.push(fileList[i].id);
                    }else if(fileList[i].response && fileList[i].response.result.id){
                        this.ids.push(fileList[i].response.result.id);
                    }
                }
            }
            this.$emit('change',this.ids);
        },
        onPreview(file) {
            let _url = file.url.indexOf('http') >= 0 ? file.url : this.$config.assetsUrl + file.url;

            window.open(_url, '_blank');
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        },
	    beforeRemoveOss(file, fileList) {
		    // return this.$confirm(`确定移除 ${ file.name }？`);
		    this.$confirm(`确定移除 ${ file.name }?`, '提示', {
			    confirmButtonText: '确定',
			    cancelButtonText: '取消',
			    type: 'warning'
		    }).then(() => {
			    this.onRemove(file,this.fileList);
		    }).catch(() => {});
	    },
        onSuccess(response, file, fileList) {
				console.log(response, file, fileList);
					this.fileList = fileList;

            if(response.code !== 0){
                this.$utils.error('上传失败');
                this.fileList = [];
            }else{
	            if(this.limit <= 1){
								console.log(response)
		            this.$emit('change', response.result.url);
	            }else {
		            this.ids.push(response.result.id);
		
		            this.$emit('change', this.ids);
	            }
            }
        },
	    beforeUpload(file){
				// console.log(file);
		    const isLt2M = file.size / 1024 / 1024 < this.maxsize;
		    if (!isLt2M) {
			    this.$message.error('上传头像图片大小不能超过 '+this.maxsize+'MB!');
		    }
				this.$emit('onName',file.name)
		    // console.log(isLt2M);
				if(isLt2M){
					this.tmpFileList.push({
						file:file,
						percent:0
					})
				}
		    return isLt2M;
	    }
    }
}
</script>
