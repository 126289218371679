<template>
    <div>
        <el-radio-group v-model="values" @change="changeVipType">
            <el-radio label="free">完全公开</el-radio>
            <el-radio label="user">注册会员</el-radio>
            <el-radio :label="item.id" v-for="(item,index) in vip_list">{{item.name}}</el-radio>
        </el-radio-group>
    </div>
</template>

<script>
export default {
    name: "BlockVip",
    data(){
        return{
            vip_list:[],
            values:'free',
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:String,
            default:'free'
        }
    },
    created() {
        this.values = this.value ? this.value : 'free';

        this.$emit("change", this.values);
    },
    watch:{
        value(n,o){
            this.values = n ? n : 'free';
        }
    },
    mounted() {
        this.getVipType();
    },
    methods:{
        changeVipType(e){
            this.$emit("change", this.values);
        },
        getVipType(){
            let _this = this;
            this.$http.httpPost({
                url: '/VipPrice/listAll',
                datas:{orderBy:'sort',sortBy:'asc',state:1,pid:[0,'<=']},
                success: (res) => {
                    let _list = [];
                    let _ids = [];

                    for(let i=0;i < res.length;i++){
                        let _data = {id:res[i].id,name:res[i].title};

                        _list.push(_data);

                        _ids.push(res[i].id);
                    }

                    _list.push({id:_ids.join(','),name:'所有VIP'});

                    _this.vip_list = _list;
                }
            })
        }
    }
}
</script>
