<template>
    <el-button type="primary" size="mini" icon="el-icon-plus">{{ sLabel }}</el-button>
</template>

<script>
export default {
    name: "AddButton",
    data() {
        return {
            sLabel: '添加'
        }
    },
    props: {
        label: {
            type: String,
            default: '添加'
        }
    },
    created() {
        this.sLabel = this.label;
    },
    watch: {
        label(n, o) {
            this.sLabel = n;
        }
    },
}
</script>
