export default [
  {
    title:'首页',
    path: '/dashboard',
    icon:'el-icon-tickets',
  },
  {
    title:'字典管理',
    path: '#',
    icon:'el-icon-tickets',
    child:[
      {title:'机构部门',path:'/type/department',query:null},
      {title:'政策分类',path:'/type/policy',query:null},
      {title:'标准分类',path:'/type/standard',query:null},
      {title:'企业性质',path:'/type/nature',query:null},
      {title:'活动专栏分类',path:'/type/zhuanti',query:null},
      // {title:'问答分类',path:'/type/question',query:null},
    ]
  },
  {
    title:'企业管理',
    path: '/company/medicine',
    icon:'el-icon-tickets',
  },
  // {
  //   title:'企业管理',
  //   path: '/company',
  //   icon:'el-icon-tickets',
  //   child:[
  //     {title:'医药企业',path:'/company/medicine',query:null},
  //     {title:'医疗器械企业',path:'/company/apparatus',query:null},
  //   ]
  // },
  {title:'政策标准',path:'#1',query:null,icon:'el-icon-present',
    child:[
      {title:'政策管理',path:'/policy/policy',query:null},
      {title:'标准管理',path:'/policy/standard',query:null},
    ]
  },
  {title:'研究报告',path:'#2',query:null,icon:'el-icon-present',
    child:[
      {title:'行业发展报告',path:'/report/industry',query:null},
      {title:'细分领域报告',path:'/report/domain',query:null},
    ]
  },
  {title:'医药数据',path:'#3',query:null,icon:'el-icon-present',
    child:[
      {title:'工业端数据',path:'/hydata/zygy',query:null},
      {title:'进出口',path:'/hydata/inout',query:null},
      {title:'商业数据',path:'/hydata/business',query:null},
      {title:'终端数据',path:'/hydata/client',query:null},
      {title:'物流总费用',path:'/hydata/wlfy',query:null},
      {title:'物流仓储及车辆',path:'/hydata/ccwlcl',query:null},
      {title:'冷链市场规模',path:'/hydata/llscgm',query:null},
    ]
  },
  // {title:'医疗器械数据',path:'#4',query:null,icon:'el-icon-present',
  //   child:[
  //     {title:'市场规模',path:'/hydata/ylqxsc',query:null},
  //     {title:'物流仓储及车辆',path:'/hydata/ylqxwlcl',query:null},
  //   ]
  // },
  {title:'产业园区',icon:'el-icon-present',path:'/hydata/park',query:null},
  {title:'会议管理',path:'#20',query:null,icon:'el-icon-present',
    child:[
      {title:'会议列表',path:'/zhuanti/list',query:null},
      {title:'讲师管理',path:'/zhuanti/speaker',query:null},
    ]
  },
  {
    title:'新闻资讯',
    path: '/news',
    icon:'el-icon-tickets',
  },
  {
    title:'权威榜单',
    path: '/prize/medicine',
    icon:'el-icon-tickets',
  },
  // {
  //   title:'权威榜单',
  //   path: '/prize',
  //   icon:'el-icon-tickets',
  //   child:[
  //     {title:'医药奖项',path:'/prize/medicine',query:null},
  //     {title:'医疗器械奖项',path:'/prize/apparatus',query:null},
  //   ]
  // },
  {title:'用户管理',path:'#11',query:null,icon:'el-icon-present',
    child:[
      {title:'会员管理',path:'/user',query:null},
      {title:'VIP价格管理',path:'/user/vip',query:null},
    ]
  },
  {
    title:'提现管理',
    path: '/cash/list',
    icon:'el-icon-tickets',
  },
  {
    title:'问答管理',
    path: '/question',
    icon:'el-icon-tickets',
  },
  {
    title:'调查问卷',
    path: '/survey',
    icon:'el-icon-tickets',
  },
  {
    title:'评论管理',
    path: '/reply',
    icon:'el-icon-tickets',
  },
  {
    title:'发票管理',
    path: '/user/invoice/log',
    icon:'el-icon-tickets',
  },
  {title:'订单管理',path:'#12',query:null,icon:'el-icon-present',
    child:[
      {title:'订单记录',path:'/order/list',query:null},
      {title:'支付记录',path:'/order/list2',query:null},
    ]
  },
  {
    title:'短信记录',
    path: '/sms',
    icon:'el-icon-tickets',
  },
  {
    title:'成员管理',
    path: '/member',
    icon:'el-icon-tickets',
  },
  {title:'系统设置',path:'#14',query:null,icon:'el-icon-present',
    child:[
      {title:'基础设置',path:'/setting/base',query:null},
      {title:'邮件设置',path:'/setting/mail',query:null},
      {title:'支付设置',path:'/setting/pay',query:null},
      {title:'服务号设置',path:'/setting/fuwu',query:null},
      {title:'小程序设置',path:'/setting/mini',query:null},
      {title:'用户协议',path:'/setting/service',query:null},
      {title:'模块设置',path:'/setting/block',query:null},
      {title:'模块权限设置',path:'/setting/block/vip',query:null},
      {title:'开票须知',path:'/setting/fapiao',query:null},
    ]
  },
  {title:'关于我们',path:'#10',query:null,icon:'el-icon-present',
    child:[
      {title:'协会简介',path:'/about/des',query:null},
      {title:'会员展示',path:'/about/kehu',query:null},
      {title:'数量设置',path:'/about/count',query:null},
      // {title:'我们的团队',path:'/about/team',query:null},
      {title:'我们的团队',path:'/about/team',query:null},
      // {title:'协会大事记',path:'/about/history',query:null},
    ]
  },
  // {title:'医疗器械关于我们',path:'#15',query:null,icon:'el-icon-present',
  //   child:[
  //     {title:'协会简介',path:'/about2/des',query:null},
  //     {title:'会员展示',path:'/about2/kehu',query:null},
  //     {title:'我们的团队',path:'/about2/team',query:null},
  //     {title:'协会大事记',path:'/about2/history',query:null},
  //   ]
  // },
];
