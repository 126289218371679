<template>
    <el-form ref="form" label-width="200px" v-if="info">
        <el-form-item :label="item.name" :prop="item.value" v-for="(item,index) in detailParams">
            <template v-if="item.type === 'sex'">
                {{item.root ? ($utils.evalGet(info,'info',item.value) === 1 ? '男' : '女') : info[item.value]}}
            </template>
            <template v-else-if="item.type === 'image'">
                <template v-if="item.root">
                    <el-image
                        style="width: 100px; height: 100px"
                        :src="$config.assetsUrl + $utils.evalGet(info,'info',item.value)"
                        fit="fill" v-if="$utils.evalGet(info,'info',item.value)"></el-image>
                    <span v-else>-</span>
                </template>
                <span v-else>{{info[item.value]}}</span>
            </template>
            <template v-else>
                {{item.root ? $utils.evalGet(info,'info',item.value) : info[item.value]}}
            </template>
        </el-form-item>
        <el-form-item>
            <el-button @click="$router.back()">返回</el-button>
        </el-form-item>
    </el-form>
    <div class="padding-top-bottom-40 dark-2 text-center" v-else>无数据</div>
</template>

<script>
export default {
    name: "PolicyEdit",
    data() {
        return {
            url:'',
            params:null,
            detailParams:null,
            info:null,
            id:''
        };
    },
    created() {
        let _config = window.config.paths();
        let _path = this.$route.path.replace('/detail','');

        if(this.$route.params.type){
            this.$set(this.formData,'type',this.$route.params.type);
        }

        this.params = _config[_path];

        this.detailParams = this.params['detail']['params'];

        this.id = this.$route.query.id ? this.$route.query.id : '';
    },
    mounted() {
        if(this.id) {
            this.getDetail();
        }
    },
    methods: {
        getDetail(x) {
            let _this = this;
            this.$http.httpPost({
                url: this.params.apiUrl+'/detail',
                datas: { id: this.id },
                success(res) {
                    _this.info = res ? res : null;

                    // console.log(eval('_this.info.'+'user.avatar'));
                }
            });
        },
    }
};
</script>
