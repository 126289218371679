<template>
	<div class="bg-light padding">
		<div class="cl">
			<div class="z" style="line-height: 28px;color:#777">
				<el-button size="small" class="margin-right" icon="el-icon-arrow-left" @click="$router.back()">返回</el-button>
				<template v-if="info">会议标题：<b style="color: #111;">{{info.title}}</b></template>
			</div>
			<add-button class="y" @click.native="toAdd"></add-button>
		</div>
		<el-table :data="list" style="width: 100%" ref="listtable" :fit="true">
			<el-table-column prop="id" label="ID"></el-table-column>
			<el-table-column prop="title" label="标题"></el-table-column>
			<el-table-column prop="title" label="类型">
				<template slot-scope="scope">
					<el-tag type="danger" v-if="scope.row.type === 'video'">视频</el-tag>
					<el-tag type="primary" v-if="scope.row.type === 'ppt'">PPT</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间"></el-table-column>
			<el-table-column label="">
				<template slot-scope="scope">
					<edit-button @click.native="$router.push({ path:'/zhuanti/videolist/edit',query:{id:scope.row.id }})"></edit-button>
					<del-button @click.native="del(scope.row.id)"></del-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="padding-top-bottom-20 text-center">
			<el-pagination
				background
				layout="total,prev, pager, next"
				:page-size="searchForm.pageSize"
				:current-page.sync="searchForm.page"
				:total="totalCount"
				@current-change="pageChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	name: "videolist",
	data() {
		return {
			id:'',
			info:null,
			list:[],
			totalCount: 0,
			searchForm: {
				zhuanti_id:'',
				pageSize: 10,
				page: 1,
				title: '',
			}
		}
	},
	created() {
		this.searchForm.zhuanti_id = this.$route.query.id ? this.$route.query.id : ''
	},
	mounted() {
		if(this.searchForm.zhuanti_id){
			this.getDetail();
			this.getList();
		}else{
			this.$alert('内容不存在或被删除', '提示', {
				confirmButtonText: '确定',
				closeOnClickModal:false,
				closeOnPressEscape:false,
				showClose:false,
				callback: action => {
					this.$router.back();
				}
			});
		}
	},
	methods: {
		getDetail() {
			let _this = this;
			
			this.$http.httpPost({
				url: '/Zhuanti/detail',
				datas: {id:this.searchForm.zhuanti_id},
				success(res) {
					_this.info = res;
				}
			});
		},
		getList() {
			let _this = this;
			
			this.$http.httpPost({
				url: '/ZhuantiVideo/listPage',
				datas: {...this.searchForm},
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			});
		},
		pageChange(e){
			this.searchForm.page = e;
			this.getList();
		},
		toAdd(){
			this.$router.push({path:'/zhuanti/videolist/edit',query:{zhuanti_id:this.searchForm.zhuanti_id}})
		},
		del(id) {
			let _this = this;
			this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					this.$http.httpPost({
						url: '/ZhuantiVideo/del',
						datas: {id: id,del:1},
						success(res) {
							_this.$utils.success("删除成功！");
							_this.getList();
						}
					});
				})
				.catch(() => {
					// _this.$utils.warning('取消了');
				});
		},
	}
}
</script>
