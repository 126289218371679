<template>
  <span>
    <el-button @click="open">选择企业</el-button>
    <el-dialog title="选择企业" append-to-body :visible.sync="isShow" width="50%" @close="onClose" class="dialog-h-400">
      <div class="margin-bottom" slot="title">
        <el-input v-model="searchForm.title" style="width: 400px;" class="margin-right"></el-input>
        <el-button type="primary" @click="toSearch">查询</el-button>
        <el-button @click="toClean">重置</el-button>
      </div>
      <el-row :gutter="10">
        <el-col :span="12" v-for="(item,index) in list" style="margin-bottom: 10px;">
          <div :class="isChoose(item) ? 'light bg-brand-0' : ''" class="bg-brand-hover hand padding-left-right border-radius-5" @click="toChoose(item)" v-html="item.title" style="height:30px;line-height: 30px;">{{item.title}}</div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </span>
<!--    <el-select-->
<!--        v-model="values"-->
<!--        multiple-->
<!--        filterable-->
<!--        default-first-option-->
<!--        placeholder="请选择"-->
<!--        @change="change">-->
<!--        <div style="flex-direction: row;align-items:center;display: flex;padding:0 5px 5px 5px;">-->
<!--            <div style="width: 385px;">-->
<!--                <input placeholder="请选择文件" type="file" ref="pathClear" id="implUserExcel" @change="implUserExcel" accept=".xlsx"/>-->
<!--            </div>-->
<!--            <a style="flex: 1;" class="margin-left" :href="$config.assetsUrl + '/company_demo.xlsx'" target="_blank">下载示例</a>-->
<!--        </div>-->
<!--        <div style="flex-direction: row;display: flex;padding:0 5px 5px 5px;">-->
<!--            <el-input placeholder="请输入内容" v-model="title"></el-input>-->
<!--            <add-button @click.native="ajaxAdd" label="添加企业" style="margin-left:5px;"></add-button>-->
<!--        </div>-->
<!--        <el-option-->
<!--            v-for="(item,index) in list"-->
<!--            :key="item.id"-->
<!--            :label="item.title"-->
<!--            :value="item.id">-->
<!--        </el-option>-->
<!--    </el-select>-->
</template>

<script>
export default {
    name: "CompanySelect",
    data(){
        return{
          list:[],
          values:[],
          title:'',
          tmpList:[],
          tmpValues:[],
          isShow:false,

          searchForm:{
            title:''
          }
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:[Array,String],
            default(){
                return []
            }
        },
      canChoose:{
        type:Boolean,
        default:false
      },
      datas:{
        type:[Array,Object],
        default(){
          return {}
        }
      },
    },
    created() {
        this.values = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      this.tmpValues = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      // this.values = this.value ? this.value : [];
    },
    watch:{
        value(n,o){
          console.log(n);
          //   this.values = n ? n : [];
          this.values = n ? JSON.parse(JSON.stringify(n)) : [];
          this.tmpValues = n ? JSON.parse(JSON.stringify(n)) : [];
        },
      // datas:{
      //     handler:(n,o)=>{
      //       console.log(n);
      //     },
      //   deep:true
      // }
    },
    mounted() {
        this.getList();
    },
    methods:{
      toSearch(){
        this.getList();
      },
      toClean(){
        this.searchForm.title = '';
        this.getList();
      },
      isChoose(e){
        let _choose = false;
        let _i = 0;

        for(let i=0;i < this.values.length;i++){
          console.log('values==='+this.values[i].id);
          console.log('e=='+e.id);
          if(this.values[i].id === e.id){
            _choose = true;
            _i = i;
            break;
          }
        }

        console.log(_choose);
        return _choose;
      },
      open(){

        if(!this.canChoose){
          this.$message.error('请先选择年份');
          return;
        }
        this.values = this.tmpValues;

        this.isShow = true;
      },
      onClose(){
        this.values = [];
        this.isShow = false;
      },
      submit(){
        this.$emit('change',this.values);

        console.log(this.values);

        this.isShow = false;
      },
      toChoose(e){
        let _choose = false;
        let _i = 0;

        for(let i=0;i < this.values.length;i++){
          if(this.values[i].id === e.id){
            _choose = true;
            _i = i;
            break;
          }
        }

        if(_choose){
          this.values.splice(_i,1);
        }else{
          this.values.push(e);
        }
      },
        implUserExcel(e){
            console.log(e);
            let _this = this;
            //拿到所导入文件的名字
            let fileName = e.target.files[0]
            //定义reader，存放文件读取方法
            let reader = new FileReader()
            //启动函数
            reader.readAsBinaryString(fileName)
            //onload在文件被读取时自动触发
            reader.onload = function (e) {
                let uploadData = [];
                //workbook存放excel的所有基本信息
                const workbook = _this.$xlsx.read(e.target.result, {type: 'binary'});
                //定义sheetList中存放excel表格的sheet表，就是最下方的tab
                let sheetList = workbook.SheetNames// 工作表名称集合

                // console.log(sheetList);
                // console.log(_this.tmpList);

                let worksheet = workbook.Sheets[sheetList[0]]; // 只能通过工作表名称来获取指定工作表
                for (let i in worksheet) {
                    // v是读取单元格的原始值
                    // console.log(key, key[0] === '!' ? worksheet[key] : worksheet[key].v);
                    // console.log(i,worksheet[i].v);


                    // console.log(_this.tmpList[worksheet[i].v]);
                    if(_this.tmpList[worksheet[i].v]){
                        let _id = _this.tmpList[worksheet[i].v].id;

                        if(_this.values.indexOf(_id) < 0){
                            _this.values.push(_id);
                        }
                    }
                }

                _this.$emit("change", _this.values);

                _this.$refs.pathClear.value = null;
                // sheetList.forEach((name) => {
                //     let worksheet = workbook.Sheets[name]; // 只能通过工作表名称来获取指定工作表
                //     // for (let key in worksheet) {
                //     //     // v是读取单元格的原始值
                //     //     console.log(key, key[0] === '!' ? worksheet[key] : worksheet[key].v);
                //     // }
                //     uploadData.push(JSON.stringify(worksheet));
                // });
            };
        },
        getList() {
            let _this = this;

            this.$http.httpPost({
                url: '/Company/listAll',
                datas: {state:1,delete_time:0,...this.datas,...this.searchForm},
                success(res) {
                    _this.list = res;

                    for(let i =0;i < res.length;i++){
                        _this.tmpList[res[i].title] = res[i];
                    }
                }
            });
        },
        change(e){
            // console.log(e);
            // this.$emit("change", e);
        },
        ajaxAdd(){
            let _this = this;

            this.$http.httpPost({
                url: '/Company/add',
                datas: {title:this.title,...this.datas},
                success(res) {
                    console.log(res);
                    _this.getList();
                    _this.title = '';
                }
            });
        }
    }
}
</script>
