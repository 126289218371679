<template>
	<div>
		<el-tag
			:key="tag"
			v-for="tag in values"
			closable
			:disable-transitions="false"
			@close="handleClose(tag)" class="margin-right">
			{{tag}}
		</el-tag>
		<el-input
			class="input-new-tag"
			v-if="inputVisible"
			v-model="inputValue"
			ref="saveTagInput"
			size="small"
			@keyup.enter.native="handleInputConfirm"
			@blur="handleInputConfirm"
			style="width: 100px;"
		/>
		<el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加</el-button><span class="margin-left dark-3">点击回车结束编辑</span>
	</div>
</template>

<script>
export default {
	name: "Tags",
	data() {
		return {
			values:[],
			inputVisible: false,
			inputValue: '',
		}
	},
	props:{
		value:{
			type:[Array,String],
			default(){
				return []
			}
		}
	},
	model:{
		prop:'value',
		event:'change'
	},
	created() {
		this.values = this.value ? this.value : [];
	},
	mounted() {
	},
	methods: {
		handleClose(tag) {
			this.values.splice(this.values.indexOf(tag), 1);
		},
		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {
				this.values.push(inputValue);
			}
			this.inputVisible = false;
			this.inputValue = '';
			
			this.$emit('change',this.values);
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
	},
	watch:{
		value:{
			handler(n,o){
				this.values = n;
			},
			deep:true,
			immediate:true
		}
	}
}
</script>
