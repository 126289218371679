<template>
    <div class="wrap mg-center">
        <div class="cl"></div>
        <el-row :gutter="20" style="margin-top:200px;">
            <el-col :span="14">
                <div class=""><img src="/images/logo2.png" height="100"/></div>
                <div class="margin-top light f-lq f40 f-i">数据管理后台</div>
                <div class="f-thin light f22 margin-top">物流与采购联合会医药物流分会</div>
            </el-col>
            <el-col :span="10">
                <div class="bg-light padding-20">
                    <div class="border-bottom brand f-b padding-bottom"><img src="/images/logo2.png" height="30" style="vertical-align: middle;margin-right: 20px;"/> 登录</div>
                    <el-form :model="loginForm" :rules="rules" ref="ruleForm" label-width="auto" class="margin-top" @keydown.enter.native="submitForm('ruleForm')">
                        <el-form-item label="" prop="username">
                            <el-input v-model="loginForm.username" placeholder="用户名">
                                <i slot="prefix" class="el-input__icon el-icon-user"></i>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="" prop="password">
                            <el-input v-model="loginForm.password" show-password placeholder="密码" maxlength="20">
                                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')" class="block">登录</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            loginForm: {
                username: '',
                password: '',
            },
            //测试数据
            testData:{
                aaa:'',
                bbb:'',
            },
            rules: {
                username: [
                    {required: true, message: '请输入用户名', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {min: 1, max: 20, message: '请输入1-20位密码', trigger: 'blur'}
                ]
            }
        };
    },
    created() {
        document.querySelector('body').style.backgroundImage = 'url("/images/bg.jpg")';
        document.querySelector('body').style.backgroundRepeat = 'no-repeat';
        document.querySelector('body').style.backgroundSize = '100% 100%';
    },
    methods: {
        submitForm(formName) {
            let _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.$http.httpPost({
                        url:'/Member/login',
                        datas:this.loginForm,
                        success(res){
                            _this.$user.setToken(res.token);
                            _this.$user.setUid(res.id);
                            _this.$user.setUserName(res.username);
                            _this.$router.replace({path:'/'});
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>
