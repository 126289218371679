<template>
    <span>
        <el-upload
            :file-list="fileList"
            :multiple="true"
            :action="$config.uploadUrl"
            list-type="picture-card"
            :data="{key:imgKey}"
            :headers="{Authorization:$user.getToken()}"
            :on-success="onSuccess"
            :on-exceed="onExceed"
            :on-preview="handlePictureCardPreview"
            accept=".jpg,.jpeg,.png,.gif"
            :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="showBigImg">
            <img width="100%" :src="bigImg" alt="">
        </el-dialog>
    </span>
</template>

<script>
export default {
    name: "ImageUpload",
    data() {
        return {
            bigImg: '',
            showBigImg: false,
            fileList:[],
            values:'',
            ids:[]
        };
    },
    props:{
        imgKey: {
            type: String,
            default: 'blog'
        },
        value:{
            type:Array,
            default(){
                return []
            }
        },
      file:{
        type:Array,
        default(){
          return []
        }
      }
    },
    created() {
        this.fileList = this.file;
    },
    watch:{
        'value': {
            handler: function (n, o) {
              // console.log(n);
                this.values = n;
            },
            deep: true
        },
      file(n,o){
					console.log(n);
					let _file = [];
					
					for(let i=0;i < n.length;i++){
						n[i].url = this.$utils.formatUrl(n[i].url);
					}
          this.fileList = n;
      }
    },
    methods: {
        handleRemove(file, fileList) {
            this.ids = [];

            this.fileList = fileList;

            fileList.forEach(item=>{
                this.ids.push(item.id);
            })

            this.$emit('change',this.ids);
        },
        handlePictureCardPreview(file) {
            this.bigImg = file.url;
            this.showBigImg = true;
        },
        onSuccess(response, file, fileList) {
            console.log(fileList);
            if(response.code !== 0){
                this.$utils.error(response.msg);
                this.fileList = [];
            }else{
                this.ids = [];

                this.fileList = fileList;
	
	              for(let i=0;i < fileList.length;i++){
									if(fileList[i].id){
										this.ids.push(fileList[i].id);
									}else if(fileList[i].response){
										this.ids.push(fileList[i].response.result.id);
									}
	              }
                // fileList.forEach(item=>{
								// 	console.log(item.response);
								// 	// console.log(item);
                //     this.ids.push(item.response.result.id);
                // })

                this.$emit('change',this.ids);
            }
        },
        onExceed(f,fl){
            console.log('323');
        }
    }
}
</script>
