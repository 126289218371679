<template>
    <el-row :gutter="20">
        <el-col :span="6">
            <div class="bg-brand-0 light padding-20">
                <div class="text-right f30 f-b">{{userCount}}</div>
                <div class="text-right f16">今日新增用户</div>
            </div>
        </el-col>
        <el-col :span="6">
            <div class="bg-success-0 light padding-20">
                <div class="text-right f30 f-b">{{orderCount}}</div>
                <div class="text-right f16">今日新增订单</div>
            </div>
        </el-col>
        <el-col :span="6">
            <div class="bg-warning-0 light padding-20">
                <div class="text-right f30 f-b">{{policyCount}}</div>
                <div class="text-right f16">今日新增政策标准</div>
            </div>
        </el-col>
        <el-col :span="6">
            <div class="bg-danger-0 light padding-20">
                <div class="text-right f30 f-b">{{reportCount}}</div>
                <div class="text-right f16">今日新增研究报告</div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        name: "DashBoard",
        data(){
            return{
                today:'',
                userCount:0,
                orderCount:0,
                policyCount:0,
                reportCount:0,
                userList:[],
                hotBidsList:[],
                hotNewsList:[],

                user_total_time:'',

                start_time:'',
                end_time:''
            }
        },
        created() {
            let _date = new Date();

            let _year = _date.getFullYear();
            let _month = _date.getMonth() + 1;
            let _day = _date.getDate();

            let _today = _year + '-' + _month + '-' + _day;

            this.today = this.$moment(_today).unix();

            this.start_time = this.$moment().weekday(1).format('YYYY-MM-DD'); //本周一
            this.end_time = this.$moment().weekday(7).format('YYYY-MM-DD'); //本周日

            this.user_total_time = this.start_time+','+this.end_time;
        },
        mounted(){
            this.getUserCount();
            this.getOrderCount();
            this.getBidsCount();
            this.getNewsCount();
            // this.getUserList();
            // this.getBidsList();
            // this.getNewsList();

            // this.getTotal('user');
        },
        methods:{
            getUserCount(){
                let _this = this;
                _this.$http.httpPost({
                    url: '/User/count',
                    datas: {create_time:[this.today,'>=']},
                    success: (res) => {
                        console.log(res);
                        _this.userCount = res.count;
                    }
                });
            },
            getOrderCount(){
                let _this = this;
                _this.$http.httpPost({
                    url: '/Order/count',
                    datas: {create_time:[this.today,'>='],state:2},
                    success: (res) => {
                        console.log(res);
                        _this.orderCount = res.count;
                    }
                });
            },
            getBidsCount(){
                let _this = this;
                _this.$http.httpPost({
                    url: '/Policy/count',
                    datas: {create_time:[this.today,'>=']},
                    success: (res) => {
                        console.log(res);
                        _this.bidsCount = res.count;
                    }
                });
            },
            getNewsCount(){
                let _this = this;
                _this.$http.httpPost({
                    url: '/Report/count',
                    datas: {create_time:[this.today,'>=']},
                    success: (res) => {
                        console.log(res);
                        _this.newsCount = res.count;
                    }
                });
            },
            getUserList(){
                let _this = this;
                _this.$http.httpPost({
                    url: '/User/listAll',
                    datas: {orderBy:'create_time',sortBy:'desc',limit:8},
                    success: (res) => {
                        _this.userList = res;
                    }
                });
            },
            getBidsList(){
                let _this = this;
                _this.$http.httpPost({
                    url: '/Bids/listAll',
                    datas: {orderBy:'click_count',sortBy:'desc',limit:8},
                    success: (res) => {
                        console.log(res);
                        _this.hotBidsList = res;
                    }
                });
            },
            getNewsList(){
                let _this = this;
                _this.$http.httpPost({
                    url: '/News/listAll',
                    datas: {orderBy:'click_count',sortBy:'desc',limit:8},
                    success: (res) => {
                        _this.hotNewsList = res;
                    }
                });
            },
            userTimeChange(e){
                console.log(e);
                this.getTotal('user',e);
            },
            getTotal(type,time = null){
                let _this = this;
                _this.$http.httpPost({
                    url: '/Total/listAll',
                    datas: {type:type,total_time:time ? time : this.user_total_time,orderBy:'total_time',sortBy:'asc'},
                    success: (res) => {
                        console.log(res);

                        let _bar1 = {bar:[],xaxis:[]};

                        for (let i=0;i < res.length;i++) {
                            _bar1.bar.push(res[i].count);

                            let _time = _this.$moment.unix(res[i].total_time).format('YYYY-M-D')
                            _bar1.xaxis.push(_time);
                        }

                        if(_this.$refs.bar1) {
                            _this.$refs.bar1.initData(
                                [
                                    {name: '注册人数', type: 'bar', color: 'blue', data: _bar1.bar},
                                ],
                                _bar1.xaxis
                            );
                        }
                    }
                });
            }
        }
    }
</script>
