<template>
	<div class="bg-light padding">
<!--		{{formData}}-->
		<el-form :model="formData" ref="form" label-width="170px">
			<el-form-item label="ID" v-if="formData && formData.id">
				{{formData.id}}
			</el-form-item>
			<el-form-item label="榜单名称" v-if="title">
				{{title}}
			</el-form-item>
			<el-form-item label="导出字段">
				<template v-for="(t,i) in formData.out_col">
					<template v-if="t">
						<div class="bg-gray-3 padding margin-bottom text-center f20 danger" style="position: sticky;top:0;z-index: 3;">{{t.title}}</div>
						<template v-for="(t2,i2) in t.datas">
							<div class="border-bottom padding-bottom margin-bottom f16">
								{{t2.label}}
								<div class="margin-top f14 dark-3" v-if="t2.des">{{t2.des}}</div>
							</div>
							<el-row :gutter="10">
								<el-col :span="item.col ? parseInt(item.col) : 6" v-for="(item,index) in t2.values">
									<el-form-item :prop="'out_col.'+i+'.datas.'+i2+'.values.'+index+'.val'">
										<el-checkbox :label="item.label" v-model="item.checked"></el-checkbox>
<!--										<el-switch-->
<!--											v-model="item.val"-->
<!--											active-color="#13ce66"-->
<!--											active-value="1"-->
<!--											inactive-value="0"-->
<!--											active-text="是"-->
<!--											inactive-text="否" v-if="item.type === 'state'">-->
<!--										</el-switch>-->
									</el-form-item>
								</el-col>
							</el-row>
						</template>
					</template>
				</template>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('form')">保存</el-button>
				<el-button @click="$router.back()">返回</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "editout",
	data() {
		return {
			title:'',
			formData:{
				id:'',
				out_col: {
					data_yysr: {
						title: '主营营业收入',
						datas: [
							{
								id: 'data1',
								label: '主营营业收入',
								values: [
									{label: '主营业务营业收入(万元)', checked:false},
									{label: '同比增长(%)', checked:false},
									{label: '主营业务利润(万元)', checked:false},
									{label: '同比增长(%)', checked:false},
									{label: '医药冷链业务营业收入(万元)',checked:false},
									{label: '同比增长(%)', checked:false},
									{label: '医药冷链业务利润(万元)', checked:false},
									{label: '同比增长(%)', checked:false},
								]
							},
							{
								id: 'data2',
								label: '各品类冷链药品营收占比',
								values: [
									{label: '疫苗类(%)',  checked:false},
									{label: '血液制品类(%)',  checked:false},
									{label: '其他生物制品(%)',  checked:false},
									{label: 'IVD(%)',  checked:false},
									{label: '其他医疗器械(%)',  checked:false},
								]
							},
						]
					},
					data_wlfy:{
						title:'物流费用',
						datas:[
							{
								id:'data1',
								label:'医药物流费用',
								values:[
									{label: '总费用(万元)',checked:false},
									{label: '同比增长(%)',checked:false},
									{label: '医药物流仓储费用(万元)',checked:false},
									{label: '医药物流运输费用(万元)',checked:false},
									{label: '医药物流管理费用(万元)',checked:false},
									{label: '其他费用(万元)',checked:false},
								]
							},
							{
								id:'data2',
								label:'医药冷链物流费用',
								values:[
									{label: '总费用(万元)',checked:false},
									{label: '同比增长(%)',checked:false},
									{label: '冷链物流仓储费用(万元)',checked:false},
									{label: '冷链物流运输费用(万元)',checked:false},
									{label: '冷链物流管理费用(万元)',checked:false},
									{label: '其他费用(万元)',checked:false},
								]
							},
							{
								id:'data3',
								label:'各品类冷链药品物流费用占比',
								values:[
									{label: '疫苗类(%)',checked:false},
									{label: '同比增长(%)',checked:false},
									{label: '血液制品类(%)',checked:false},
									{label: '同比增长(%)',checked:false},
									{label: '其他生物制品(%)',checked:false},
									{label: '同比增长(%)',checked:false},
									{label: 'IVD(%)',checked:false},
									{label: '同比增长(%)',checked:false},
									{label: '其他医疗器械(%)',checked:false},
									{label: '同比增长(%)',checked:false},
								]
							}
						]
					},
					data_bwx:{
						title:'保温箱&冷藏箱信息',
						datas:[
							{
								id:'data1',
								label:'保温箱&冷藏箱总量',
								values:[
									{label: '总数量(个)',checked:false},
									{label: '自有(个)',checked:false},
									{label: '租赁(个)',checked:false},
									{label: '年度使用费用(万元)',checked:false},
									{label: '同比增长(%)',checked:false},
									{label: '验证费用(万元)',checked:false},
									{label: '品牌',checked:false},
									{label: '是否经过GSP验证',checked:false},
									{label: '温湿度记录仪品牌',checked:false},
									{label: '产能(个/年)',checked:false},
								]
							},
						]
					},
					data_car:{
						title:'车辆信息',
						datas:[
							{
								id:'data1',
								label:'医药专用运输车',
								values:[
									{label: '总数量(辆)',checked:false},
								]
							},
							{
								id:'data2',
								label:'医药专用运输车辆自有',
								values:[
									{label: '合计(辆)',checked:false},
									{label: '4.2m以下(辆)',checked:false},
									{label: '4.2m~7.2m(辆)',checked:false},
									{label: '7.6m~9.6m(辆)',checked:false},
									{label: '12.5m~17m(辆)',checked:false},
								]
							},
							{
								id:'data3',
								label:'医药专用运输车辆外协',
								values:[
									{label: '合计(辆)',checked:false},
									{label: '4.2m以下(辆)',checked:false},
									{label: '4.2m~7.2m(辆)',checked:false},
									{label: '7.6m~9.6m(辆)',checked:false},
									{label: '12.5m~17m(辆)',checked:false},
								]
							},
							{
								id:'data4',
								label:'冷藏车总数',
								values:[
									{label: '总数量(辆)',checked:false},
								]
							},
							{
								id:'data5',
								label:'冷藏车自有',
								values:[
									{label: '合计(辆)',checked:false},
									{label: '4.2m以下(辆)',checked:false},
									{label: '4.2m~7.2m(辆)',checked:false},
									{label: '7.6m~9.6m(辆)',checked:false},
									{label: '12.5m~17m(辆)',checked:false},
								]
							},
							{
								id:'data6',
								label:'冷藏车外协',
								values:[
									{label: '合计(辆)',checked:false},
									{label: '4.2m以下(辆)',checked:false},
									{label: '4.2m~7.2m(辆)',checked:false},
									{label: '7.6m~9.6m(辆)',checked:false},
									{label: '12.5m~17m(辆)',checked:false},
								]
							},
							{
								id:'data7',
								label:'其他车型',
								values:[
									{label: '车型',checked:false},
									{label: '数量(辆)',checked:false},
								]
							},
							{
								id:'data8',
								label:'车辆品牌信息',
								values:[
									{label: '普通专用车辆品牌',checked:false},
									{label: '冷藏车辆品牌',checked:false},
									{label: '改装车辆生产厂家',checked:false},
									{label: '制冷机组厂家',checked:false},
									{label: '温湿度记录仪品牌',checked:false},
									{label: '运输管理信息系统品牌',checked:false},
									{label: '验证费用(万元)',checked:false},
									{label: '产能(辆/年)',checked:false},
								]
							},
						]
					},
					data_cangchu:{
						title:'仓储信息',
						datas:[
							{
								id:'data1',
								label:'仓储基本信息',
								values:[
									{label: '仓库总个数(个)',checked:false},
									{label: '仓库总面积(㎡)',checked:false},
									{label: '仓库总容积(㎥)',checked:false},
								]
							},
							{
								id:'data2',
								label:'冷藏库（2℃~8℃）',
								values:[
									{label: '总面积(㎡)',checked:false},
									{label: '总容积(㎥)',checked:false},
								]
							},
							{
								id:'data3',
								label:'冷冻库（-15℃～-25℃以下）',
								values:[
									{label: '总面积(㎡)',checked:false},
									{label: '总容积(㎥)',checked:false},
								]
							},
							{
								id:'data4',
								label:'阴凉库（0℃～20℃）',
								values:[
									{label: '总面积(㎡)',checked:false},
									{label: '总容积(㎥)',checked:false},
								]
							},
							{
								id:'data5',
								label:'常温库（10℃～30℃）',
								values:[
									{label: '总面积(㎡)',checked:false},
									{label: '总容积(㎥)',checked:false},
								]
							},
							{
								id:'data6',
								label:'其他温区库',
								values:[
									{label: '总面积(㎡)',checked:false},
									{label: '总容积(㎥)',checked:false},
								]
							},
							{
								id:'data7',
								label:'自动化立体库',
								values:[
									{label: '个数(个)',checked:false},
									{label: '总面积(㎡)',checked:false},
									{label: '总容积(㎥)',checked:false},
									{label: '集成商',checked:false},
									{label: '出入库能力(箱/时)',checked:false},
									{label: '分拣能力(件/时)',checked:false},
									{label: '平均在库天数(天)',checked:false},
								]
							},
							{
								id:'data8',
								label:'普通库',
								values:[
									{label: '个数(个)',checked:false},
									{label: '总面积(㎡)',checked:false},
									{label: '总容积(㎥)',checked:false},
									{label: '出入库能力(箱/时)',checked:false},
									{label: '分拣能力(件/时)',checked:false},
									{label: '平均在库天数(天)',checked:false},
								]
							},
							{
								id:'data9',
								label:'',
								values:[
									{label: '仓库制冷机组品牌',checked:false},
									{label: '仓库温湿度记录仪品牌',checked:false},
									{label: '仓库信息管理系统品牌',checked:false},
									{label: '仓库所在省份地址',checked:false},
								]
							},
						]
					},
					data_yunshu:{
						title:'运输组织',
						datas:[
							{
								id:'data1',
								label:'运输方式',
								values:[
									{label: '自有运输(%)',checked:false},
									{label: '外协运输(%)',checked:false},
								]
							},
							{
								id:'data2',
								label:'运输模式',
								values:[
									{label: '干线运输(%)',checked:false},
									{label: '支线运输(%)',checked:false},
									{label: '同城配送(%)',checked:false},
								]
							},
							{
								id:'data3',
								label:'货源结构',
								values:[
									{label: '整车运输(%)',checked:false},
									{label: '零担运输(%)',checked:false},
								]
							},
							{
								id:'data4',
								label:'车辆平均周转天数',
								des:'车辆往返任务完结时间',
								values:[
									{label: '干线(天)',checked:false},
									{label: '支线(天)',checked:false},
									{label: '城配(天)',checked:false},
								]
							},
							{
								id:'data5',
								label:'车辆满载率',
								values:[
									{label: '干线(%)',checked:false},
									{label: '支线(%)',checked:false},
									{label: '城配(%)',checked:false},
								]
							},
							{
								id:'data6',
								label:'车辆平均月度行驶里程（KM）',
								values:[
									{label: '月平均行驶里程(KM)',checked:false},
								]
							},
							{
								id:'data7',
								label:'核心运输线路',
								values:[
									{label: '干线',checked:false},
									{label: '支线',checked:false},
									{label: '城配',checked:false},
								]
							},
						]
					},
					data_rencai:{
						title:'人才情况',
						datas:[
							{
								id:'data1',
								label:'总人数',
								values:[
									{label: '公司总人数(人)',checked:false},
								]
							},
							{
								id:'data2',
								label:'性别方面',
								values:[
									{label: '男性(%)',checked:false},
									{label: '女性(%)',checked:false},
								]
							},
							{
								id:'data3',
								label:'学历情况',
								values:[
									{label: '初中以下(%)',checked:false},
									{label: '初中/中职(%)',checked:false},
									{label: '高中/高职(%)',checked:false},
									{label: '本科(%)',checked:false},
									{label: '硕士及以上(%)',checked:false},
								]
							},
							{
								id:'data4',
								label:'工资水平',
								values:[
									{label: '3000元/月以下(%)',checked:false},
									{label: '3000-5000元/月(%)',checked:false},
									{label: '5000-10000元/月(%)',checked:false},
									{label: '10000元/月以上(%)',checked:false},
								]
							},
							{
								id:'data5',
								label:'年龄结构人数',
								values:[
									{label: '20-30岁占比(%)',checked:false},
									{label: '30-40岁占比(%)',checked:false},
									{label: '40-50岁占比(%)',checked:false},
									{label: '50岁以上(%)',checked:false},
								]
							},
							{
								id:'data6',
								label:'人员稳定性方面',
								values:[
									{label: '一线从业人员流失率(%)',checked:false},
									{label: '需求人数(人)',checked:false},
									{label: '助理级从业人员流失率(%)',checked:false},
									{label: '需求人数(人)',checked:false},
									{label: '中级管理人员流失率(%)',checked:false},
									{label: '需求人数(人)',checked:false},
									{label: '高级管理人员流失率(%)',checked:false},
									{label: '需求人数(人)',checked:false},
								]
							},
							{
								id:'data7',
								label:'从业人员培养模式',
								des:'内部培训、外聘讲师培训、外部培训、管培生培训',
								values:[
									{label: '一线从业人员',checked:false},
									{label: '助理级从业人员',checked:false},
									{label: '中级管理人员',checked:false},
									{label: '高级管理人员',checked:false},
								]
							},
							{
								id:'data8',
								label:'培训频次',
								des:'至少每月一次、至少每季度一次、至少每半年一次、至少每年一次',
								values:[
									{label: '一线从业人员',checked:false},
									{label: '助理级从业人员',checked:false},
									{label: '中级管理人员',checked:false},
									{label: '高级管理人员',checked:false},
								]
							},
						]
					},
					data_kehu:{
						title:'主要客户',
						datas:[
							{
								id:'data1',
								label:'主要客户',
								des:'列举5家，一行一个',
								values:[
									{label: '主要客户',checked:false},
								]
							},
						]
					},
					data_xinxixitong:{
						title:'信息系统投入金额',
						datas:[
							{
								id:'data1',
								label:'投入金额',
								values:[
									{label: '自主研发(万元)',checked:false},
									{label: '购买使用(万元)',checked:false},
								]
							},
						]
					},
					data_zidonghua:{
						title:'自动化设施设备投入金额',
						datas:[
							{
								id:'data1',
								label:'投入金额',
								values:[
									{label: '自主研发(万元)',checked:false},
									{label: '购买使用(万元)',checked:false},
								]
							},
						]
					}
				}
			}
		}
	},
	created() {
		this.formData.id = this.$route.query.id ? this.$route.query.id : '';
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		getDetail() {
			let _this = this;
			
			if(!this.formData.id){
				return;
			}
			
			this.$http.httpPost({
				url: '/Prize/detail',
				datas: {id: this.formData.id},
				success(res) {
					_this.title = res.title;
					if(res.out_col) {
						_this.formData.out_col = res.out_col;
					}
				}
			});
		},
		submitForm(formName) {
			let _this = this;
			this.$refs[formName].validate(valid => {
				//为true时验证通过
				if (valid) {
					_this.create();
				} else {
					return false;
				}
			});
		},
		create() {
			let _this = this;
			
			let _datas = JSON.parse(JSON.stringify(this.formData));
			_datas.out_col = JSON.stringify(_datas.out_col)
			
			this.$http.httpPost({
				url: '/Prize/editout',
				datas: _datas,
				success(res) {
					_this.$utils.success('保存成功！');
					// _this.$router.back();
				}
			});
		},
	}
}
</script>
