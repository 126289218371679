<template>
    <el-form :model="formData" :rules="rules" ref="form" label-width="200px" style="width: 700px;">
        <el-form-item label="ID" v-if="formData.id">
            {{formData.id}}
        </el-form-item>
        <el-form-item label="年份" prop="year">
            <year-select v-model="formData.year" style="width: 500px;" @change="change"></year-select>
        </el-form-item>
        <el-form-item label="合计">
            {{totalIncome}}
        </el-form-item>
        <el-form-item :label="item.name" prop="" v-for="(item,index) in formData.values">
            <div v-if="typeof item.val === 'object'">
                <el-form-item label="" :prop="'values.' + index + '.val.income'" :rules="[{required: true,message:item.name+'必填'},{validator:$utils.rulePriceCanZero}]" label-width="10px">
                    <el-input v-model="item.val.income" maxlength="10" show-word-limit>
                        <template slot="append">占比：{{ (item.val.income_percent * 100).toFixed(2)}}%</template>
                    </el-input>
                </el-form-item>
            </div>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('form')">保存</el-button>
            <el-button @click="$router.back()">返回</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    name: "edit",
    data() {
        return {
            url:'',
            params:null,
            editParams:null,
            fileList: [],
            formData: {
                id:'',
                year: '',
                type:'wlfy',
                values:[
                    {name:'医药工业主营业务收入',val:{income:'0',profit:'0',income_percent:'0',profit_percent:'0'}},
                    {name:'进口额',val:{income:'0',profit:'0',income_percent:'0',profit_percent:'0'}},
                    {name:'医药商业总额',val:{income:'0',profit:'0',income_percent:'0',profit_percent:'0'}},
                    {name:'医药电商B2B总额',val:{income:'0',profit:'0',income_percent:'0',profit_percent:'0'}},
                    {name:'医药电商B2C总额',val:{income:'0',profit:'0',income_percent:'0',profit_percent:'0'}},
                ],
                total_profit:0,
                total_income:0
            },
            rules: {
                year:[{required:true,message:'年份必填'}],
            }
        };
    },
    created() {
        let _config = window.config.paths();
        let _path = this.$route.path.replace('/edit','');

        this.params = _config[_path];

        this.formData.id = this.$route.query.id ? this.$route.query.id : '';
    },
    mounted() {
        if(this.formData.id) {
            this.getDetail();
            this.url = this.params.apiUrl+'/edit';
        }else{
            this.url = this.params.apiUrl+'/add';
        }
    },
    computed: {
        // 计算属性的 getter
        totalIncome: function () {
            // `this` 指向 vm 实例
            let _total = 0;
            for(let i =0 ;i < this.formData.values.length;i++){
                _total += parseFloat(this.formData.values[i].val.income);
            }

            for(let i =0 ;i < this.formData.values.length;i++){
                let _percent = (parseFloat(this.formData.values[i].val.income) / _total).toFixed(4);

                this.formData.values[i].val.income_percent = isNaN(_percent) ? 0 : _percent;
            }

            this.formData.total_income = isNaN(_total) ? 0 : _total.toFixed(2)

            return isNaN(_total) ? 0 : _total.toFixed(2);
        }
    },
    methods:{
        getDetail(){
            let _this = this;
            _this.$http.httpPost({
                url: this.params.apiUrl+'/detail',
                datas: {id:this.formData.id},
                success(res) {
                    console.log(res);
                    _this.formData.year = res.year;
                    _this.formData.values = res.values;
                    _this.formData.total_income = res.total_income;
                    _this.formData.total_profit = res.total_profit;
                    // _this.$utils.success('保存成功！');
                    // _this.$router.back();
                }
            });
        },
        change(e){
            console.log(e);
            let _this = this;
            _this.$http.httpPost({
                url: this.params.apiUrl+'/detail',
                datas: {type:this.formData.type,year:e},
                success(res) {
                    if(Object.prototype.isPrototypeOf(res) && Object.keys(res).length > 0) {
                        _this.formData.id = res.id;
                        _this.formData.values = res.values;
                        _this.formData.total_income = res.total_income;
                        _this.formData.total_profit = res.total_profit;
                        _this.url = _this.params.apiUrl+'/edit';
                    }else{
                        _this.url = _this.params.apiUrl+'/add';
                        _this.formData.id = '';
                        _this.formData.values = [
                            {name:'医药工业主营业务收入',val:{income:'0',profit:'0',income_percent:'0',profit_percent:'0'}},
                            {name:'进口额',val:{income:'0',profit:'0',income_percent:'0',profit_percent:'0'}},
                            {name:'医药商业总额',val:{income:'0',profit:'0',income_percent:'0',profit_percent:'0'}},
                            {name:'医药电商B2B总额',val:{income:'0',profit:'0',income_percent:'0',profit_percent:'0'}},
                            {name:'医药电商B2C总额',val:{income:'0',profit:'0',income_percent:'0',profit_percent:'0'}},
                        ];
                        _this.formData.total_income = 0;
                        _this.formData.total_profit = 0;
                    }
                    // _this.$utils.success('保存成功！');
                    // _this.$router.back();
                }
            });
        },
        submitForm(formName){
            let _this = this;
            console.log(this.formData);
            //通过传递form表单ref名称，来调用表单的validate方法
            this.$refs[formName].validate(valid => {
                //为true时验证通过
                if (valid) {
                    _this.$http.httpPost({
                        url: _this.url,
                        datas: _this.formData,
                        success(res) {
                            _this.$utils.success('保存成功！');
                            _this.$router.back();
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    }
}
</script>
