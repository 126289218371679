<template>
    <el-form :model="formData" :rules="rules" ref="form" label-width="100px">
        {{formData}}
        <el-form-item label="ID" v-if="formData.id">
            {{formData.id}}
        </el-form-item>
        <el-form-item :label="item.name" :prop="typeof item.value === 'string' ? item.value : ''" v-for="(item,index) in editParams">
            <year-select v-model="formData.year" style="width: 500px;" v-if="item.value === 'year'"></year-select>

            <template v-else-if="item.value.length > 1">
                <el-row>
                    <el-col :span="12" v-for="(item2,index2) in item.value">
                        <el-input v-model="formData.values[index2][item2.sval]" style="width: 500px;"></el-input>
                    </el-col>
                </el-row>
            </template>
            <el-input v-model="formData[item.value]" style="width: 500px;" v-else></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('form')">保存</el-button>
            <el-button @click="$router.back()">返回</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    name: "edit",
    data() {
        return {
            url:'',
            params:null,
            editParams:null,
            fileList: [],
            formData: {
                id:'',
                year: '',
                type:'',
                values:[
                    {name:'化学原料制造',val:{income:'',profit:''}}
                ]
            },
            rules: {}
        };
    },
    created() {
        let _config = window.config.paths();
        let _path = this.$route.path.replace('/edit', '');

        if (this.$route.params.type) {
            this.$set(this.formData, 'type', this.$route.params.type);
        }

        this.params = _config[_path];

        console.log(this.params);

        this.editParams = this.params['edit']['params'];

        this.formData.id = this.$route.query.id ? this.$route.query.id : '';

        let _fm = [];
        for(let i = 0;i<this.editParams.length;i++){
            // if(this.params[i].value === 'permission' || this.params[i].value === 'attachment') {
            //     this.$set(this.formData,this.params[i].value,[]);
            // }else{
            //     this.$set(this.formData,this.params[i].value,'');
            // }

            if(typeof this.editParams[i].value === 'string') {
                if (this.editParams[i].rq) {
                    if (this.editParams[i].value === 'price') {
                        this.rules[this.editParams[i].value] = [{
                            required: true,
                            message: this.editParams[i].name + '必填',
                            validator: this.$utils.rulePriceCanZero
                        }];
                    } else {
                        this.rules[this.editParams[i].value] = [{
                            required: true,
                            message: this.editParams[i].name + '必填'
                        }];
                    }
                }
            }else{
                // let _a = {name:this.editParams[i].name,val:}
            }
        }

        console.log(this.rules);
    },
    methods:{
        submitForm(from){

        }
    }
}
</script>
