<template>
    <div>
        <el-button size="small" type="primary" slot="right-btn" @click="$router.back()">返回</el-button>
        <div class="page-content-main" v-if="detail">
            <div class="border-bottom" style="line-height: 50px;">订单基本信息</div>
            <div>
                <el-form label-width="auto" class="margin-top" style="width:800px;">
                    <el-form-item label="订单编号：">
                        {{ detail.order_no }}
                    </el-form-item>
                    <el-form-item label="下单时间：">
                        {{detail.create_time}}
                    </el-form-item>
                    <el-form-item label="订单金额：">
                        ￥{{ parseFloat(detail.order_amount).toFixed(2) }}
                    </el-form-item>
                    <el-form-item label="实际支付金额：">
                        ￥{{ parseFloat(detail.pay_amount).toFixed(2) }}
                    </el-form-item>
                    <el-form-item label="订单状态：">
                        <el-tag type="info" disable-transitions v-if="detail.state === 0">已取消</el-tag>
                        <el-tag type="info" disable-transitions v-if="detail.state === 1">未付款</el-tag>
                        <el-tag type="warning" disable-transitions v-if="detail.state === 2">已付款、待发货</el-tag>
                        <el-tag type="success" disable-transitions v-if="detail.state === 3">已发货</el-tag>
                    </el-form-item>
                </el-form>
            </div>
            <div class="border-bottom" style="line-height: 50px;">订单商品</div>
            <div v-if="detail.goods">
                <el-form label-width="auto" class="margin-top" style="width:800px;">
                    <el-form-item label="购买商品：">
                        <b v-if="detail.order_type === 'domain'">细分领域报告</b>
                        <b v-if="detail.order_type === 'question'">问答</b>
	                    <b v-if="detail.order_type === 'answer'">问答(购买答案)</b>
                        <b v-if="detail.order_type === 'industry'">行业发展报告</b>
	                    <b v-if="detail.order_type === 'vip'">购买VIP</b>
                    </el-form-item>
                    <el-form-item label="商品名称：">
                        {{detail.goods.title}}
                    </el-form-item>
                    <el-form-item label="商品金额：">
                        ￥{{parseFloat(detail.goods.price).toFixed(2)}}
                    </el-form-item>
                </el-form>
            </div>
	        <div class="border-bottom" style="line-height: 50px;">买家信息</div>
	        <div v-if="detail.user">
		        <el-form label-width="auto" class="margin-top" style="width:800px;">
			        <el-form-item label="姓名：">{{detail.user.name ? detail.user.name : '-'}}</el-form-item>
			        <el-form-item label="手机号码：">{{detail.user_detail.mobile ? detail.user_detail.mobile : '-'}}</el-form-item>
			        <el-form-item label="昵称：">{{detail.user.nickname ? detail.user.nickname : '-'}}</el-form-item>
			        <el-form-item label="企业信息：">{{detail.user.company ? detail.user.company : '-'}}</el-form-item>
			        <el-form-item label="职位：">{{detail.user.job ? detail.user.job : '-'}}</el-form-item>
		        </el-form>
	        </div>
            <div class="border-bottom" style="line-height: 50px;" v-if="detail.order_type === 'industry'">送货信息</div>
            <div v-if="detail.order_type === 'industry' && detail.address">
                <el-form label-width="auto" class="margin-top" style="width:800px;">
                    <el-form-item label="收件人：">
                        {{ detail.address.consignee }}
                    </el-form-item>
                    <el-form-item label="收件地址：">
                        {{ detail.address.address }}
                    </el-form-item>
                    <el-form-item label="联系电话：">
                        {{ detail.address.phone }}
                    </el-form-item>
                </el-form>
            </div>
            <div class="border-bottom" style="line-height: 50px;" v-if="detail.order_type === 'industry'">物流信息</div>
            <div v-if="detail.order_type === 'industry' && detail.address">
                <el-form label-width="auto" class="margin-top" style="width:800px;">
                    <el-form-item label="物流公司：">
                        {{ detail.shipping_name }}
                    </el-form-item>
                    <el-form-item label="物流单号：">
                        {{ detail.shipping_no }}
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "detail",
    data() {
        return {
            detail: null,
            id: null
        }
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            let _this = this;

            _this.$http.httpPost({
                url: '/Order/detail',
                datas: {id: _this.id},
                success: (res) => {
                    _this.detail = res;
                }
            })
        }
    }
}
</script>
