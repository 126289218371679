<template>
    <el-button type="success" size="mini" icon="el-icon-edit" :disabled="disabled">编辑</el-button>
</template>

<script>
export default {
    name: "EditButton",
    props: {
        click: '',
        disabled: {
            type: Boolean,
            default: false
        }
    },
}
</script>
