<template>
    <el-select v-model="values" placeholder="请选择" @change="change" value-key="code" style="width: 500px;" v-if="isObj">
        <el-option
            v-for="item in list"
            :key="item.code"
            :label="item.name"
            :value="item"></el-option>
    </el-select>
    <el-select v-model="values" placeholder="请选择" @change="change" style="width: 500px;" v-else>
        <el-option
            v-for="item in list"
            :key="item.code"
            :label="item.name"
            :value="item.code"></el-option>
    </el-select>
</template>

<script>
export default {
    name: "ProvinceSelect",
    data(){
        return{
            list:[],
            values:''
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:[String,Array,Object],
            default(){
                return {}
            }
        },
        isObj:{
            type:Boolean,
            default:true
        },
    },
    created() {
        this.values = this.value ? this.value : '';
    },
    watch:{
        value(n,o){
            this.values = n ? n : '';
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList() {
            let _this = this;
            this.$http.httpPost({
                url: "/Divisions/listAll",
                datas: {pid:'3318'},
                success(res) {
                    for(let i=0;i < res.length;i++) {
                        let _a = {code: res[i].code, name: res[i].name}
                        _this.list.push(_a);
                    }
                }
            });
        },
        change(e){
            this.$emit("change", e);
        }
    }
}
</script>
