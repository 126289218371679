<template>
  <el-date-picker
    v-model="values"
    type="month"
    placeholder="选择年月"
    @change="change">
  </el-date-picker>
</template>

<script>
export default {
  name: "YearMonthSelect",
  data() {
    return {
      values:''
    }
  },
  props:{
    value:{
      type:String,
      default:''
    }
  },
  model:{
    prop:'value',
    event:'change'
  },
  created() {
    this.values = this.value ? this.$moment(this.value).toDate() : '';
  },
  mounted() {
  },
  methods: {
    change(e){
      console.log(this.$moment(e).format('YYYY-MM-DD 00:00:00'));
      // let _year = e.getFullYear();
      // let _month = e.getMonth() + 1;
      // let _day = e[0].getDate();
      //
      // let _year2 = e[1].getFullYear();
      // let _month2 = e[1].getMonth() + 1;
      // let _day2 = e[1].getDate();
      //
      this.$emit('change',e ? this.$moment(e).format('YYYY-MM-DD 00:00:00') : '');
    }
  },
  watch:{
    value(n,o){
      if(n){
        this.values = this.$moment(n).toDate();

      }else{
        this.values = ''
      }
    },
  }
}
</script>
