<template>
    <div>
        <add-button slot="right-btn" @click.native="showForm" v-if="dataList.length > 0"></add-button>
        <div>
            <el-table :data="dataList" default-expand-all style="width: 100%" row-key="id" :tree-props="{children: 'child'}" :indent="30" v-if="dataList.length > 0">
                <el-table-column
                    prop="name"
                    label="名称">
                </el-table-column>
                <el-table-column label="标识" prop="atype" v-if="hasAType.indexOf(type) >= 0"></el-table-column>
                <el-table-column prop="head_img" label="封面图" v-if="hasHeadImg.indexOf(type) >= 0">
                    <template slot-scope="scope">
                        <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.head_img ? $config.assetsUrl + scope.row.head_img : ''"
                            :preview-src-list="scope.row.head_img ? [$config.assetsUrl + scope.row.head_img] : []" v-if="scope.row.head_img"></el-image>
                        <span v-else>——</span>
                    </template>
                </el-table-column>
                <el-table-column prop="icon" label="图标" v-if="hasIcon.indexOf(type) >= 0">
                    <template slot-scope="scope">
                        <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.icon ? $config.assetsUrl + scope.row.icon : ''"
                            :preview-src-list="scope.row.icon ? [$config.assetsUrl + scope.row.icon] : []" v-if="scope.row.icon"></el-image>
                        <span v-else>——</span>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="内容" v-if="hasContent.indexOf(type) >= 0">
                    <template slot-scope="scope">
                        {{scope.row.content ? scope.row.content : '—'}}
                    </template>
                </el-table-column>
	            <el-table-column prop="des" label="描述">
		            <template slot-scope="scope">
			            {{scope.row.des ? scope.row.des : '—'}}
		            </template>
	            </el-table-column>
                <el-table-column
                    prop="state"
                    label="状态" width="250">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.state === 1 ? 'success' : 'danger'" disable-transitions>
                            {{ scope.row.state === 0 ? '禁用' : '可用' }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="sort"
                    label="排序">
                </el-table-column>
                <el-table-column
                    prop="create_time"
                    label="创建时间"></el-table-column>
                <el-table-column
                    prop="update_time"
                    label="修改时间">
                    <template slot-scope="scope">
                        {{ scope.row.update_time === 0 ? '—' : $moment.unix(scope.row.update_time).format('YYYY-M-D HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column>
                    <div slot-scope="scope" style="text-align: right;">
                        <edit-button @click.native="showForm(scope.row)"></edit-button>
                        <del-button @click.native="del(scope.row.id)"></del-button>
                    </div>
                </el-table-column>
            </el-table>
            <div class="text-center null-data f14 info" v-else>
                暂无数据，<add-button slot="right-btn" @click.native="showForm"></add-button>
            </div>
            <div class="text-center padding-top-bottom-20" v-if="dataList.length > 0">
                <el-pagination
                    background
                    layout="total,prev, pager, next"
                    :page-size="pageSize"
                    :current-page.sync="page"
                    :total="totalCount"
                    @current-change="pageChange">
                </el-pagination>
            </div>
            <el-dialog title="添加" :visible.sync="show" @close="close" width="800px">
                <el-form :model="formData" :rules="rules" ref="form" status-icon label-width="80px"  @keydown.enter.native="submit('form')">
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="formData.name" autocomplete="off" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
<!--                    <el-form-item label="分类" v-if="hasMMType.indexOf(type) >= 0">-->
<!--                        <type-select v-model="formData.mm_type" :options="[{name:'医药',id:'medicine'},{name:'医疗器械',id:'apparatus'}]"></type-select>-->
<!--                    </el-form-item>-->
	                <el-form-item label="标识" prop="atype" v-if="hasAType.indexOf(type) >= 0" :rules="[{required:true,message:'标识必填'}]">
		                <el-input v-model="formData.atype" autocomplete="off" maxlength="20" show-word-limit></el-input>
	                </el-form-item>
                    <el-form-item label="封面图" v-if="hasHeadImg.indexOf(type) >= 0" :rules="[{required:true,message:'封面图必填'}]">
                        <head-img-upload @change="uploadChange" v-model="formData.head_img"></head-img-upload>
                    </el-form-item>
                    <el-form-item label="图标" v-if="hasIcon.indexOf(type) >= 0" :rules="[{required:true,message:'图标必填'}]">
                        <head-img-upload @change="uploadChangeIcon" v-model="formData.icon"></head-img-upload>
                    </el-form-item>
                    <el-form-item label="内容" v-if="hasContent.indexOf(type) >= 0" :rules="[{required:true,message:'内容必填'}]">
                        <el-input v-model="formData.content" autocomplete="off" maxlength="100" show-word-limit></el-input>
                    </el-form-item>
	                <el-form-item label="描述">
		                <el-input v-model="formData.des" autocomplete="off" maxlength="100" show-word-limit></el-input>
	                </el-form-item>
                    <el-form-item label="排序" prop="sort">
                        <el-input v-model="formData.sort" autocomplete="off" maxlength="5" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-switch
                            v-model="formData.state"
                            active-text="可用"
                            inactive-text="禁用"
                            :active-value="1"
                            :inactive-value="0">
                        </el-switch>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="show = false">取 消</el-button>
                    <el-button type="primary" @click="submit('form')">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    name: "MemberList",
    data() {
        return {
            dataList: [],
            totalPage: 0,
            totalCount: 0,
            pageSize: 20,
            page: 1,
            hasHeadImg:['standard','policy'],
            hasContent:['standard','policy'],
            hasMMType:['standard','policy'],
	        hasAType:['standard','policy'],
            hasIcon:['standard','policy'],
            type:'',
            headImg:'',
            icon:'',
            formData:{
                id:'',
                akey:'',
	            atype:'',
                name:'',
                sort:0,
                state:1,
                head_img:'',
                content:'',
                mm_type:'medicine',
                icon:'',
	            des:''
            },
            show:false,
            rules: {
                name: [{required: true, message: '必填'}],
                sort: [{required: true, message: '必填'}],
            },
        }
    },
    created() {
        this.type = this.formData.akey = this.$route.params.type ? this.$route.params.type : '';
    },
    methods: {
        getList() {
            let _this = this;
            this.dataList = [];
            this.$http.httpPost({
                url: '/Type/listPage',
                datas: {akey:this.formData.akey,page: this.page, pageSize: this.pageSize, orderBy: 'sort asc,create_time', sortBy: 'desc',mm_type:'medicine'},
                success: (res) => {
                    _this.dataList = res.list;
                    _this.totalPage = res.totalPage;
                    _this.totalCount = res.totalCount;
                }
            });
        },
        submit(formName){
            let _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let _url = '';

                    if(_this.formData.id){
                        _url = '/Type/edit';
                    }else{
                        _url = '/Type/add';
                    }
                    _this.$http.httpPost({
                        url: _url,
                        datas: this.formData,
                        success: (res) => {
                            _this.show = false;
                            _this.getList();
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        del(id) {
            var _this = this;
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$http.httpPost({
                    url: '/Type/del',
                    datas: {id: id,akey:this.formData.akey,del:1},
                    success: (res) => {
                        _this.getList();
                    }
                });
            }).catch(() => {
            });
        },
        showForm(item){
            this.formData.sort = item.sort ? item.sort : 0;
            this.formData.name = item.name ? item.name : '';
            this.formData.id = item.id ? item.id : '';
            this.formData.state = item.state === 0 ? 0 : 1;
            this.formData.head_img = item.head_img ? item.head_img : '';
            this.headImg = item.head_img ? item.head_img : '';
            this.icon = item.icon ? item.icon : '';
            this.formData.content = item.content ? item.content : '';
            this.formData.atype = item.atype ? item.atype : '';
            this.formData.icon = item.icon ? item.icon : '';
            this.formData.des = item.des ? item.des : '';

            this.show = true;
        },
        close(){
            this.formData.sort = 0;
            this.formData.name = '';
            this.formData.id = '';
            this.formData.head_img = '';
            this.formData.state = 1;
            this.formData.content = '';
            this.formData.atype = '';
            this.formData.icon = '';
            this.formData.des = '';

            this.headImg = '';
            this.icon = '';
        },
        pageChange(page) {
            this.page = page;
            this.getList();
        },
        uploadChange(e){
            this.formData.head_img = e;
        },
        uploadChangeIcon(e){
            this.formData.icon = e;
        }
    },
    mounted() {
        this.getList();
    }
}
</script>
