<template>
  <div>
    <div class="margin-top">
<!--      {{formData}}-->
      <el-form :model="formData" ref="form" label-width="170px">
        <el-form-item label="ID" v-if="formData && formData.id">
          {{formData.id}}
        </el-form-item>
        <el-form-item label="企业名称" v-if="companyInfo">
          {{companyInfo.title}}--医疗器械数据
        </el-form-item>
        <el-form-item label="年份" prop="year" :rules="[{required: true,message:'年份必填',trigger:'blur'}]">
          <year-select placeholder="-请先选择年份-" v-model="year" @change="changeYear" style="width: 500px;"></year-select>
          <el-button type="danger" class="margin-left" v-if="formData && formData.id" @click="toDel">删除{{year}}年数据</el-button>
        </el-form-item>
        <template v-if="formData.year">
          <template v-for="(t,i) in formData.data_col">
            <template v-if="t">
              <div class="bg-gray-3 padding margin-bottom text-center f20 danger" style="position: sticky;top:0;z-index: 3;" v-if="t.title">{{t.title}}</div>
              <el-button size="mini" v-if="!!t.autoAdd" type="primary" @click="addRow(defData[i].datas,i)">添加数据行</el-button>
              <template v-for="(t2,i2) in t.datas">
                <div class="border-bottom padding-bottom margin-bottom f16">
                  {{t2.label}}<el-button size="mini" v-if="!!t.autoAdd" @click="delRow(i2,i)" class="margin-top">删除</el-button>
                  <div class="margin-top f14 dark-3" v-if="t2.des">{{t2.des}}</div>
                </div>
                <el-row :gutter="10">
                  <el-col :span="item.col ? parseInt(item.col) : 6" v-for="(item,index) in t2.values">
                    <el-form-item :label="item.label" :prop="'data_col.'+i+'.datas.'+i2+'.values.'+index+'.val'" :rules="item.rule ? [{required: item.rq ? (typeof item.rq === 'string' ? item.rq.bool() : item.rq) : false,message:'不能为空'},{pattern:item.rule ? $regExp[item.rule] : null,message:'格式不正确'}] : [{required: item.rq ? (typeof item.rq === 'string' ? item.rq.bool() : item.rq) : false,message:'不能为空'}]">
                      <el-switch
                          v-model="item.val"
                          active-color="#13ce66"
                          active-value="1"
                          inactive-value="0"
                          active-text="是"
                          inactive-text="否" v-if="item.type === 'state'">
                      </el-switch>
                      <el-select v-model="item.val" v-else-if="item.type === 'peiyang'" clearable>
                        <el-option label="暂无" value=""></el-option>
                        <el-option label="内部培训" value="内部培训"></el-option>
                        <el-option label="外聘讲师培训" value="外聘讲师培训"></el-option>
                        <el-option label="外部培训" value="外部培训"></el-option>
                        <el-option label="管培生培训" value="管培生培训"></el-option>
                      </el-select>
                      <el-select v-model="item.val" v-else-if="item.type === 'peixun'" clearable>
                        <el-option label="暂无" value=""></el-option>
                        <el-option label="至少每月一次" value="至少每月一次"></el-option>
                        <el-option label="至少每季度一次" value="至少每季度一次"></el-option>
                        <el-option label="至少每半年一次" value="至少每半年一次"></el-option>
                        <el-option label="至少每年一次" value="至少每年一次"></el-option>
                      </el-select>
                      <push-time v-model="item.val" style="width: 100%;" v-else-if="item.type === 'date'"></push-time>
                      <el-input v-model="item.val" rows="3" type="textarea" :maxlength="item.max ? item.max : null" v-else-if="item.type === 'textarea'"></el-input>
                      <el-input v-model="item.val" style="max-width: 310px;" :maxlength="item.max ? item.max : null" v-else>
                        <template slot="append" v-if="item.dw">{{item.dw}}</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </template>
          </template>
        </template>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">保存</el-button>
          <el-button @click="$router.back()">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<!--`data_ztyj` longtext COMMENT '整体业绩',-->
<!--`data_slys` longtext COMMENT '商流营收',-->
<!--`data_wlys` longtext COMMENT '物流营收',-->
<!--`data_dsfwlyw` longtext COMMENT '第三方物流业务分类占比（按营收占比）',-->
<!--`data_qtys` longtext COMMENT '其他营收（万元）',-->
<!--`data_ywcb` longtext COMMENT '业务成本占比',-->
<!--`data_rencai` longtext COMMENT '员工基本情况（人）',-->
<!--`data_cangchu` longtext COMMENT '仓储能力',-->
<!--`data_cangku` longtext COMMENT '仓库布局（个）',-->
<!--`data_ysnl` longtext COMMENT '运输能力（辆）',-->
<!--`data_yslx` longtext COMMENT '运输类型',-->
<!--`data_bwx` longtext COMMENT '保温箱&冷藏箱',-->
<!--`data_fwzd` longtext COMMENT '服务终端个数',-->
<!--`data_ksyw` longtext COMMENT '跨省业务占比',-->
<!--`data_xxh` longtext COMMENT '信息化/自动化应用',-->
<script>
export default {
  name: "yiyao",
  data(){
    return{
      formData:{
        id:'',
        tid:'',
        year:'',
        data_col:null
      },
      year:'',
      rules:{

      },
      url:'',

      defData:{
        data_ztyj: {
          title:'整体业绩',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '主营业务收入',val:'0',dw:'万元',rule:'money',max:10,col:8,rq:true},
                {label: '上年同期',val:'0',dw:'万元',rule:'money',max:10,col:8,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,col:8,rq:true},
                {label: '成本',val:'0',dw:'万元',rule:'money',max:10,col:8,rq:true},
                {label: '上年同期',val:'0',dw:'万元',rule:'money',max:10,col:8,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,col:8,rq:true},
                {label: '净利润',val:'0',dw:'万元',rule:'money',max:10,col:8,rq:true},
                {label: '上年同期',val:'0',dw:'万元',rule:'money',max:10,col:8,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,col:8,rq:true},
                {label: '年利润率',val:'0',dw:'%',rule:'float',max:10,col:8,rq:true},
                {label: '上年同期',val:'0',dw:'%',rule:'float',max:10,col:8,rq:true},
                {label: '同比增长',val:'0',dw:'%',rule:'float',max:10,col:8,rq:true},
              ]
            }
          ]
        },
        data_slys:{
          title:'商流营收',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '高值耗材',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '高值耗材进口产品占比',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: '低值耗材',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '低值耗材进口产品占比',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: '医疗设备',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: '医疗设备进口产品占比',val:'0',dw:'%',rule:'money',max:10,rq:true},
                {label: 'IVD设备及试剂',val:'0',dw:'万元',rule:'money',max:10,rq:true},
                {label: 'IVD设备及试剂进口产品占比',val:'0',dw:'%',rule:'money',max:10,rq:true},
              ]
            }
          ]
        },
        data_wlys:{
          title:'物流营收',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '自营物流',val:'0',dw:'万元',rule:'money',max:10,col:6,rq:true},
                {label: '第三方物流',val:'0',dw:'万元',rule:'money',max:10,col:6,rq:true},
              ]
            },
          ]
        },
        data_dsfwlyw:{
          title:'第三方物流业务分类占比',
          des:'按营收占比',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '高值医用耗材',val:'0',dw:'%',rule:'money',max:10,col:6,rq:true},
                {label: '低值医用耗材',val:'0',dw:'%',rule:'money',max:10,col:6,rq:true},
                {label: '医疗设备',val:'0',dw:'%',rule:'money',max:10,col:6,rq:true},
                {label: 'IVD设备及试剂',val:'0',dw:'%',rule:'money',max:10,col:6,rq:true},
              ]
            }
          ]
        },
        data_qtys:{
          title:'其他营收',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '其他营收',dw:'万元',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            }
          ]
        },
        data_ywcb:{
          title:'业务成本占比',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '仓储成本',dw:'%',val:'0',rule:'money',max:10,rq:true},
                {label: '运输成本',dw:'%',val:'0',rule:'money',max:10,rq:true},
                {label: '人力成本',dw:'%',val:'0',rule:'money',max:10,rq:true},
                {label: '管理成本',dw:'%',val:'0',rule:'money',max:10,rq:true},
              ]
            }
          ]
        },
        data_spd:{
          title:'SPD业务整体情况',
          datas:[
            {
              id:'data1',
              label:'营收',
              values:[
                {label: 'SPD营收',dw:'万元',val:'0',rule:'money',max:10,rq:true},
                {label: 'SPD净利润',dw:'万元',val:'0',rule:'money',max:10,rq:true},
              ]
            },
            {
              id:'data2',
              label:'管理品类占比',
              values:[
                {label: '高值医药耗材占比',dw:'%',val:'0',rule:'money',max:10,rq:true},
                {label: '低值医药耗材占比',dw:'%',val:'0',rule:'money',max:10,rq:true},
                {label: 'IDV设备及试剂占比',dw:'%',val:'0',rule:'money',max:10,rq:true},
              ]
            },
            {
              id:'data3',
              label:'投入人员数量',
              values:[
                {label: '总人数',dw:'人',val:'0',rule:'money',max:10,rq:true},
                {label: '占企业总人数',dw:'%',val:'0',rule:'positiveInteger',max:10,rq:true},
              ]
            },
            {
              id:'data4',
              label:'业务成本比',
              values:[
                {label: '物流成本',dw:'%',val:'0',rule:'money',max:10,rq:true},
                {label: '软件成本',dw:'%',val:'0',rule:'money',max:10,rq:true},
                {label: '硬件成本',dw:'%',val:'0',rule:'money',max:10,rq:true},
                {label: '人员成本',dw:'%',val:'0',rule:'money',max:10,rq:true},
                {label: '管理成本',dw:'%',val:'0',rule:'money',max:10,rq:true},
              ]
            },
            {
              id:'data5',
              label:'',
              values:[
                {label: '软件服务商',dw:'',val:'',rule:'',max:40,rq:false},
              ]
            },
            {
              id:'data6',
              label:'',
              values:[
                {label: '硬件服务商',dw:'',val:'',rule:'',max:40,rq:false},
              ]
            },
            {
              id:'data7',
              label:'服务医院数量',
              values:[
                {label: '总数',dw:'个',val:'0',rule:'positiveInteger',max:10,rq:true,col:24},
                {label: '华北',dw:'个',val:'0',rule:'positiveInteger',max:10,rq:true},
                {label: '华东',dw:'个',val:'0',rule:'positiveInteger',max:10,rq:true},
                {label: '华中',dw:'个',val:'0',rule:'positiveInteger',max:10,rq:true},
                {label: '华南',dw:'个',val:'0',rule:'positiveInteger',max:10,rq:true},
                {label: '西南',dw:'个',val:'0',rule:'positiveInteger',max:10,rq:true},
                {label: '西北',dw:'个',val:'0',rule:'positiveInteger',max:10,rq:true},
                {label: '东北',dw:'个',val:'0',rule:'positiveInteger',max:10,rq:true}
              ]
            },
          ]
        },
        data_spdxm:{
          title:'SPD项目具体情况',
          autoAdd:true,
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '医院名称',dw:'',val:'',rule:'',max:30,rq:false},
                {label: '实施时间',dw:'',val:'',rule:'',max:30,rq:false,type:'date'},
                {label: '科室',dw:'',val:'',rule:'',max:30,rq:false},
                {label: '年采购体量',dw:'万元',val:'0',rule:'money',max:30,rq:true},
                {label: '团队人员数量',dw:'人',val:'0',rule:'positiveInteger',max:10,rq:true},
                {label: '收费模式',dw:'',val:'',rule:'',max:100,rq:false,type:'textarea'},
                {label: '收费费率',dw:'%',val:'0',rule:'money',max:10,rq:true},
              ]
            },
          ]
        },
        data_rencai:{
          title:'员工基本情况',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '总人数',dw:'人',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '自有',dw:'人',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '外包',dw:'人',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '高中及以下',dw:'人',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '大专',dw:'人',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '本科',dw:'人',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '研究生及以上',dw:'人',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
              ]
            },
            {
              id:'data2',
              label:'性别方面',
              values:[
                {label: '男性',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '女性',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data3',
              label:'工资水平',
              values:[
                {label: '3000元/月以下',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '3000-5000元/月',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '5000-10000元/月',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '10000元/月以上',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
              ]
            },
            {
              id:'data4',
              label:'年龄结构人数',
              values:[
                {label: '20-30岁占比',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '30-40岁占比',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '40-50岁占比',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '50岁以上',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
              ]
            },
            {
              id:'data5',
              label:'人员稳定性方面',
              values:[
                {label: '一线从业人员流失率',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '需求人数',dw:'人',tip:'一线从业人员需求人数',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '助理级从业人员流失率',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '需求人数',dw:'人',tip:'助理级从业人员需求人数',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '中级管理人员流失率',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '需求人数',dw:'人',tip:'中级管理人员需求人数',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
                {label: '高级管理人员流失率',dw:'%',val:'0',rule:'money',max:10,col:6,rq:true},
                {label: '需求人数',dw:'人',tip:'高级管理人员需求人数',val:'0',rule:'positiveInteger',max:10,col:6,rq:true},
              ]
            },
            {
              id:'data6',
              label:'从业人员培养模式',
              des:'内部培训、外聘讲师培训、外部培训、管培生培训',
              values:[
                {label: '一线从业人员',dw:'',val:'',col:6,type:'peiyang'},
                {label: '助理级从业人员',dw:'',val:'',col:6,type:'peiyang'},
                {label: '中级管理人员',dw:'',val:'',col:6,type:'peiyang'},
                {label: '高级管理人员',dw:'',val:'',col:6,type:'peiyang'},
              ]
            },
            {
              id:'data7',
              label:'培训频次',
              des:'至少每月一次、至少每季度一次、至少每半年一次、至少每年一次',
              values:[
                {label: '一线从业人员',dw:'',val:'',col:6,type:'peixun'},
                {label: '助理级从业人员',dw:'',val:'',col:6,type:'peixun'},
                {label: '中级管理人员',dw:'',val:'',col:6,type:'peixun'},
                {label: '高级管理人员',dw:'',val:'',col:6,type:'peixun'},
              ]
            },
          ]
        },
        data_cangchu:{
          title:'仓储能力',
          datas:[
            {
              id:'data1',
              label:'',
              des:'',
              values:[
                {label: '总数',dw:'个',val:'0',col:8,rule:'positiveInteger',rq:true},
                {label: '自建',dw:'个',val:'0',col:8,rule:'positiveInteger',rq:true},
                {label: '租赁',dw:'个',val:'0',col:8,rule:'positiveInteger',rq:true},
                {label: '总面积',dw:'㎡',val:'0',col:24,rule:'money',rq:true},
                {label: '冷藏库（2℃～8℃）面积（平方米）',dw:'㎡',val:'0',col:6,rule:'money',rq:true},
                {label: '冷冻库(-15℃~-25℃)面积（平方米）',dw:'㎡',val:'0',col:6,rule:'money',rq:true},
                {label: '恒温库（0~20℃）平方米',dw:'㎡',val:'0',col:6,rule:'money',rq:true},
                {label: '常温库（10℃~30℃）平方米',dw:'㎡',val:'0',col:6,rule:'money',rq:true},
                {label: '总容积',dw:'m³',val:'0',col:24,rule:'money',rq:true},
                {label: '冷藏库容积（2℃～8℃）（立方米）',dw:'m³',val:'0',col:6,rule:'money',rq:true},
                {label: '冷冻库(-15℃~-25℃)容积（立方米）',dw:'m³',val:'0',col:6,rule:'money',rq:true},
                {label: '恒温库（0℃~20℃）立方米',dw:'m³',val:'0',col:6,rule:'money',rq:true},
                {label: '常温库（10℃~30℃）立方米',dw:'m³',val:'0',col:6,rule:'money',rq:true},
              ]
            },
          ]
        },
        data_cangku:{
          title:'仓库布局',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '华东区域仓库',dw:'个',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '华南区域仓库',dw:'个',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '华北区域仓库',dw:'个',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '华中区域仓库',dw:'个',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '西南区域仓库',dw:'个',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '东北区域仓库',dw:'个',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '西北区域仓库',dw:'个',val:'0',col:6,rule:'positiveInteger',rq:true},
              ]
            },
          ]
        },
        data_ysnl:{
          title:'运输能力',
          datas:[
            {
              id:'data1',
              label:'常温车',
              values:[
                {label: '常温车总数',dw:'辆',val:'0',col:24,rule:'positiveInteger',rq:true},
              ]
            },
            {
              id:'data2',
              label:'',
              values:[
                {label: '常温车自有总数',dw:'辆',val:'0',col:24,rule:'positiveInteger',rq:true},
                {label: '4.2m以下',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '4.2m~7.2m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '7.6m~9.6m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '13m~17m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
              ]
            },
            {
              id:'data3',
              label:'',
              values:[
                {label: '常温车外包总数',dw:'辆',val:'0',col:24,rule:'positiveInteger',rq:true},
                {label: '4.2m以下',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '4.2m~7.2m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '7.6m~9.6m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '13m~17m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
              ]
            },
            {
              id:'data4',
              label:'冷藏车',
              values:[
                {label: '冷藏车总数',dw:'辆',val:'0',col:24,rule:'positiveInteger',rq:true},
              ]
            },
            {
              id:'data5',
              label:'',
              values:[
                {label: '冷藏车自有总数',dw:'辆',val:'0',col:24,rule:'positiveInteger',rq:true},
                {label: '4.2m以下',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '4.2m~7.2m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '7.6m~9.6m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '13m~17m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
              ]
            },
            {
              id:'data6',
              label:'',
              values:[
                {label: '冷藏车外包总数',dw:'辆',val:'0',col:24,rule:'positiveInteger',rq:true},
                {label: '4.2m以下',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '4.2m~7.2m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '7.6m~9.6m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '13m~17m',dw:'辆',val:'0',col:6,rule:'positiveInteger',rq:true},
              ]
            },
          ]
        },
        data_yslx:{
          title:'运输类型',
          datas:[
            {
              id:'data1',
              label:'运输方式',
              values:[
                {label: '自有运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '外协运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data2',
              label:'运输模式',
              values:[
                {label: '干线运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '支线运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '同城配送',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data3',
              label:'货源结构',
              values:[
                {label: '整车运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '零担运输',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data4',
              label:'车辆平均周转天数',
              des:'车辆往返任务完结时间',
              values:[
                {label: '干线',dw:'天',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '支线',dw:'天',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '城配',dw:'天',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data5',
              label:'车辆满载率',
              values:[
                {label: '干线',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '支线',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
                {label: '城配',dw:'%',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data6',
              label:'车辆平均月度行驶里程（KM）',
              values:[
                {label: '月平均行驶里程',dw:'KM',val:'0',rule:'money',max:10,col:8,rq:true},
              ]
            },
            {
              id:'data7',
              label:'核心运输线路',
              values:[
                {label: '干线',dw:'',val:'',col:8},
                {label: '支线',dw:'',val:'',col:8},
                {label: '城配',dw:'',val:'',col:8},
              ]
            },
            // {
            //   id:'data1',
            //   label:'',
            //   values:[
            //     {label: '干线运输占比',dw:'%',val:'0',col:6,rule:'money'},
            //     {label: '城配运输占比',dw:'%',val:'0',col:6,rule:'money'},
            //     {label: '整车运输占比',dw:'%',val:'0',col:6,rule:'money'},
            //     {label: '零担运输占比',dw:'%',val:'0',col:6,rule:'money'},
            //   ]
            // },
          ]
        },
        data_bwx:{
          title:'保温箱&冷藏箱',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '保温箱&冷藏箱使用总量',dw:'个',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '保温箱&冷藏箱自有',dw:'个',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '保温箱&冷藏箱租赁',dw:'个',val:'0',col:6,rule:'positiveInteger',rq:true},
                {label: '年度使用费用',dw:'万元',val:'0',col:6,rule:'money',rq:true},
              ]
            },
          ]
        },
        data_fwzd:{
          title:'服务终端',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '服务终端个数',dw:'个',val:'0',col:24,rule:'positiveInteger',rq:true},
              ]
            },
          ]
        },
        data_ksyw:{
          title:'跨省业务占比',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '跨省业务占比',dw:'%',val:'0',col:24,rule:'money',rq:true},
              ]
            },
          ]
        },
        data_xxh:{
          title:'信息化/自动化应用',
          datas:[
            {
              id:'data1',
              label:'',
              values:[
                {label: '仓库信息管理系统品牌',dw:'',val:'',col:24,rule:''},
                {label: '信息系统应用情况',dw:'',val:'',col:24,rule:'',type:'textarea'},
                {label: '自动化和机械化设备应用情况',dw:'',val:'',col:24,rule:'',type:'textarea'},
                {label: '自动化和机械化设备投资金额',dw:'万元',val:'0',col:24,rule:'money'},
              ]
            },
          ]
        }
      },

      companyInfo:null
    }
  },
  created() {
    this.formData.tid = this.$route.query.id ? this.$route.query.id : '';
  },
  mounted() {
    if(this.formData.tid) {
      this.getDetail();
      this.getCompany();
      // this.url = '/CompanyDataQixie/edit';
    }else{
      // this.url = '/CompanyDataQixie/add';

      this.initData();
    }
    // this.getPermission();
  },
  methods:{
    addRow(t,i){
      let _data = JSON.parse(JSON.stringify(t[0]));

      _data.id = 'data'+(this.formData.data_col[i].datas ? this.formData.data_col[i].datas.length + 1 : 1);

      console.log(t,i);
      console.log(_data);
      if(!this.formData.data_col[i].datas){
        this.$set(this.formData.data_col[i],'datas',[]);
      }
      this.formData.data_col[i].datas.push(_data);
    },
    delRow(index,i){
      this.formData.data_col[i].datas.splice(index,1);

      if(this.formData.data_col[i].datas.length <= 0){
        this.formData.data_col[i].datas = [];
      }
    },
    toDel(){
      let _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
            this.$http.httpPost({
              url: '/CompanyDataQixie/del',
              datas: {id: this.formData.id,del:1},
              success(res) {
                _this.$utils.success("删除成功！");
                _this.getDetail();
              }
            });
          }).catch(() => {
            // _this.$utils.warning('取消了');
          });
    },
    initData(){
      this.formData.id = '';
      this.formData.data_col = JSON.parse(JSON.stringify(this.defData));
    },
    changeYear(e){
      this.year = e;
      this.formData.year = e;
      this.formData.id = '';

      this.getDetail();
    },
    getCompany(){
      let _this = this;

      this.$http.httpPost({
        url: '/Company/detail',
        datas: {id:this.formData.tid},
        success(res) {
          console.log(res);
          _this.companyInfo = res;
        }
      });
    },
    getDetail() {
      let _this = this;
      if(this.formData.year && this.formData.tid) {
        this.$http.httpPost({
          url: '/CompanyDataQixie/detail',
          datas: {tid: this.formData.tid, year: this.formData.year},
          success(res) {
            console.log(res);

            _this.initData();

            if (res) {
              _this.formData.id = res.id;
              _this.formData.tid = res.tid;
              _this.formData.year = res.year;
              // _this.formData.data_col = {};

              for(let i in res){
                if(i.indexOf('data_') === 0){
                  if(res[i]) {
                    _this.formData.data_col[i] = res[i];
                  }
                }
              }
            }else{
              // _this.$refs.form.resetFields();
              _this.initData();
            }

            // if(_this.formData.province_code && _this.formData.province_name){
            //   _this.province = {code:_this.formData.province_code,name:_this.formData.province_name};
            // }
          }
        });
      }
    },
    create() {
      let _this = this;

      if(this.formData.id) {
        this.url = '/CompanyDataQixie/edit';
      }else{
        this.url = '/CompanyDataQixie/add';
      }

      this.$http.httpPost({
        url: this.url,
        datas: {...this.formData,...this.editData},
        success(res) {
          _this.$utils.success('保存成功！');
          _this.$router.back();
        }
      });
    },
    submitForm(formName) {
      //存在回调的方法，因为回调外和回调内的作用域不一致，所以务必为 _this 复制 this，否则在回调中无法访问this对象
      let _this = this;
      //通过传递form表单ref名称，来调用表单的validate方法
      this.$refs[formName].validate(valid => {
        //为true时验证通过
        if (valid) {
          _this.create();
        } else {
          _this.$nextTick(()=>{
            let _dom = document.getElementsByClassName('is-error');

            document.querySelector('.el-main').scrollTo({behavior:'smooth',top:_dom[0].offsetParent.offsetTop-60});
          })
          console.log("error submit!!");
          _this.$message.error('请检查表单');
          return false;
        }
      });
    },
  }
}
</script>
<style>
.el-input__inner{padding-right:0px !important;}
</style>