<template>
  <div class="bg-light padding-20">
    <el-form
        :model="formData"
        status-icon
        :rules="rules"
        ref="form"
        label-width="auto"
        class="margin-top"
        style="width:800px;"
    >
        <el-form-item label="会员单位数量" prop="member_company_count">
            <el-input v-model="formData.member_company_count" style="width:500px;">
            </el-input>
        </el-form-item>
	    <el-form-item label="医药流通企业会员单位数量" prop="yiyaoliutong_company_count">
		    <el-input v-model="formData.yiyaoliutong_company_count" style="width:500px;">
		    </el-input>
	    </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('form')">确定</el-button>
            <el-button type="" @click="$router.back()">返回</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
    name: "add",
    data() {
        return {
            formData: {
	            member_company_count: 0,
	            yiyaoliutong_company_count: 0,
            },
            rules: {
	            member_company_count: [{required: true,message:'数量必填'},{pattern:this.$regExp.positiveInteger,message: '请输入正确的数量'}],
	            yiyaoliutong_company_count: [{required: true,message:'数量必填'},{pattern:this.$regExp.positiveInteger,message: '请输入正确的数量'}],
            }
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        submitForm(formName) {
            let _this = this;
            console.log(_this.formData);
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.httpPost({
                        url: "/SystemSetting/edit",
                        datas: {..._this.formData},
                        success: res => {
                            if (res === "ok") {
                                _this.$message.success("修改成功");
                            } else {
                                _this.$message.error("修改失败");
                            }
                            // _this.$message.info(res.msg);
                            // _this.$router.replace({path: '/cate/list'})
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        getDetail() {
            let _this = this;

            let _skeys = [];

            for (let k in this.formData) {
                _skeys.push(k);
            }

            _this.$http.httpPost({
                url: "/SystemSetting/getSystem",
                datas: {skey: _skeys},
                success: res => {
                    _this.formData = res;
                }
            });
        }
    }
};
</script>
