export default {
    // apiUrl:'http://127.0.0.1:9501/Admin',
    // uploadUrl:'http://127.0.0.1:9501/Admin/Upload/upload',
    // assetsUrl:'http://127.0.0.1:9501',
    // editorUrl:'http://127.0.0.1:9501',
    // apiUrl:'https://api.hc-chaincloud.org.cn/Admin',
    // uploadUrl:'https://api.hc-chaincloud.org.cn/Admin/Upload/upload',
    // assetsUrl:'https://api.hc-chaincloud.org.cn/static',
    
    //线上生产
    // apiUrl:'//api.hc-chaincloud.org.cn/Admin',
    // uploadUrl:'//api.hc-chaincloud.org.cn/Admin/Upload/upload',
    // assetsUrl:'//api.hc-chaincloud.org.cn',
    // editorUrl:'//api.hc-chaincloud.org.cn',
    
    //线上测试
    apiUrl:'//api.test.hc-chaincloud.org.cn/Admin',
    uploadUrl:'//api.test.hc-chaincloud.org.cn/Admin/Upload/upload',
    assetsUrl:'//api.test.hc-chaincloud.org.cn/static',
    editorUrl:'//api.test.hc-chaincloud.org.cn',
    ossUrl:'http://chaincloud.oss-cn-beijing.aliyuncs.com',
    
    //本地测试
    // apiUrl:'//127.0.0.1:9504/Admin',
    // uploadUrl:'//127.0.0.1:9504/Admin/Upload/upload',
    // assetsUrl:'//127.0.0.1:9504',
    // editorUrl:'//127.0.0.1:9504',

    sideMenu:[
        {title:'概况',path:'/dashboard',query:null,icon:'fa fa-tachometer'},
        {title:'系统设置',path:'/system/show',query:null,icon:'fa fa-tachometer'},
        {title:'权限管理',path:'/rule',query:null,icon:'el-icon-present',
            child:[
                {title:'路由管理',path:'/rule/path/list',query:null},
                {title:'角色管理',path:'/rule/group/list',query:null},
                {title:'成员管理',path:'/member/list',query:null},
            ]
        },
        // {name:'产品',path:'/product',query:null,icon:'el-icon-present',
        //     sub:[
        //         {name:'产品管理',path:'/product/list',query:null},
        //         {name:'产品价格',path:'/product/price',query:null},
        //     ]
        // },
        // {name:'问题管理',path:'/question',query:null,icon:'el-icon-present',
        //     sub:[
        //         {name:'问题分类',path:'/questionType/list',query:null},
        //         {name:'问题列表',path:'/question/list',query:null},
        //     ]
        // },
        // {name:'微信管理',path:'/wx',query:null,icon:'el-icon-present',
        //     sub:[
        //         {name:'消息记录',path:'/wx/msg/list',query:null},
        //         {name:'自动回复',path:'/wx/reply/list',query:null},
        //         {name:'菜单管理',path:'/wx/menu/list',query:null},
        //     ]
        // },
        // {name:'禁用词',path:'/voidword',query:null,icon:'fa fa-tachometer'},
        // {name:'用户管理',path:'/user/list',query:null,icon:'el-icon-user'},
        // {name:'订单',path:'/order/list',query:null,icon:'el-icon-shopping-cart-full'},
        // {name:'资金流水',path:'/dashboard',query:null,icon:'el-icon-coin'},
        // {name:'云营销',path:'/invert',query:null,icon:'el-icon-cloudy',
        //     sub:[
        //         {name:'推广人管理',path:'/invert/list',query:null},
        //         {name:'结算管理',path:'/product/price',query:null},
        //     ]
        // },
        // {name:'成员管理',path:'/member',query:null,icon:'el-icon-burger'},
    ],
    publicKey:"-----BEGIN PUBLIC KEY-----\n" +
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDBb6/yCvGHaWaCtcY4eJUE+zp7\n" +
        "swdsptJFa4hmmY0SHXM2mzsohE0lFYwu9ZaQoXFrQAsMbUKvlXLuJWx9Kw8oYONl\n" +
        "bG+hjl/BYJBowbESqvgb6pEJr2bpmlKFM7x9Rz5PDuassUJxW2o6OU3I+IDajaRh\n" +
        "fFZz3rTrzJDtOhGGIQIDAQAB\n" +
        "-----END PUBLIC KEY-----\n",
    privateKey:"-----BEGIN RSA PRIVATE KEY-----\n" +
        "MIICXQIBAAKBgQDBb6/yCvGHaWaCtcY4eJUE+zp7swdsptJFa4hmmY0SHXM2mzso\n" +
        "hE0lFYwu9ZaQoXFrQAsMbUKvlXLuJWx9Kw8oYONlbG+hjl/BYJBowbESqvgb6pEJ\n" +
        "r2bpmlKFM7x9Rz5PDuassUJxW2o6OU3I+IDajaRhfFZz3rTrzJDtOhGGIQIDAQAB\n" +
        "AoGBAKQeUrWVu18cejEkcM2w4R6c2P3i48W3tGfZ2JzUYvCftAT8p7C0r49rPMkK\n" +
        "7XhAwa4xZC5i9VVsA1syvV1ljzFDkLPWW+MHVU9nFAe3CiE3JqSVkS8/DnlRtnLs\n" +
        "1zloiMygAEe30S1DWvnfMnanKfq6Nmky6AsjHKNeV/stFoLRAkEA9mSGyq86LXxt\n" +
        "6ZU96T05h2As4Fv/TvLl2GxHJfcl0N6FmqYWgsIPX/K/1w9FjaRfan/NB6j2ZPj6\n" +
        "eQ19rcrbNwJBAMj6jcpJTTHQtp1J9NFDBr/aeMWQFc/cmNoeDuTShGFHGq7ibV8Y\n" +
        "1+WpQC3sRxRNrNKWGTXRgJd6GCIf9thOxWcCQQCUdIdekeuMwn77EUfnQ1NpRMJl\n" +
        "QCj5KZLhLoRD1MC9M4XuYwoHKOPYnMuHyZTPve0bnvsrFVu4BOftjfpef25LAkBN\n" +
        "/az4SunB4xR6hTCT5EbCPK2RPRC50Qkf23o8IdVtSoJ4SzXWKXXekYduSrpqjpn6\n" +
        "PnUaBWq5XY/v7ieEWU0RAkBYycpeYSHFl6kuopAHC5LrTKzHDGM7asbsy7MtBHdS\n" +
        "VWcIafuxsxAQd2jAVvZNrATlW+cRdkl6BlYL9SeeEnsn\n" +
        "-----END RSA PRIVATE KEY-----"
}
